import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const AddAttribute = ({ index, attribute, setAttribute, formErrors }) => {
  console.log("Index");
  console.log(attribute);
  const handleAttributeChange = (e, field) => {
    console.log("Attribute change");
    console.log(e.target.value);
    attribute[field] = e.target.value;
    setAttribute(attribute);
  };

  return (
    <>
      <TextField
        fullWidth
        margin="normal"
        label="Nom de l'attribut"
        value={attribute.name}
        onChange={(e) => handleAttributeChange(e, "name")}
        error={
          formErrors[`name${index}`] ? formErrors[`name${index}`] !== "" : false
        }
        helperText={formErrors[`name${index}`]}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Description de l'attribut"
        value={attribute.description}
        onChange={(e) => handleAttributeChange(e, "description")}
        error={
          formErrors[`description${index}`]
            ? formErrors[`description${index}`] !== ""
            : false
        }
        helperText={formErrors[`description${index}`]}
      />
      <FormControl fullWidth>
        <InputLabel id="attribute-type">Type de l'attribut</InputLabel>
        <Select
          labelId="attribute-type"
          value={attribute.type}
          onChange={(e) => handleAttributeChange(e, "type")}
          label="Type de l'attribute"
          error={
            formErrors[`type${index}`]
              ? formErrors[`type${index}`] !== ""
              : false
          }
        >
          <MenuItem value="INTEGER">Entier</MenuItem>
          <MenuItem value="STRING">Texte</MenuItem>
          <MenuItem value="DECIMAL">Décimal</MenuItem>
          <MenuItem value="BOOLEAN">Booléen</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default AddAttribute;
