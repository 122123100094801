// @ts-ignore
import ApiRoutes from "domain/data/ApiRoutes";
import axios from "domain/lib/axios";
import authHeader from "../utils/authHeader";
import { useLocalStorage } from "./useLocalStorage";

// @ts-ignore
export const useUser = ({ errorsHandler, globalErrorsHandler } = {}) => {
  
  const [apiKey, setApiKey] = useLocalStorage("apiKey");

  // @ts-ignore
  const getAll = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.get(ApiRoutes.user.getAll(), {
        headers: authHeader(apiKey),
      });
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };


  // @ts-ignore
  const getById = async ({ onErrors, onSuccess, userId } = {}) => {
    try {
      let res = await axios.get(ApiRoutes.user.getById(userId));
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const banishById = async ({ onErrors, onSuccess, userId } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.user.banishById(userId), {
        headers: authHeader(apiKey),
      });
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

   // @ts-ignore
  const notifyById = async ({ onErrors, onSuccess, userId, data } = {}) => {
    try {
        console.log("NotifyById method called with ", userId, data)
      let res = await axios.post(ApiRoutes.user.notifyById(userId), data, {
        headers: authHeader(apiKey),
      });
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };


   // @ts-ignore
   const notifyAll = async ({ onErrors, onSuccess, data } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.user.notifyAll(), data, {
        headers: authHeader(apiKey),
      });
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };


  return {
    banishById,
    getAll,
    getById,
    notifyById,
    notifyAll,
  }
};
