import {
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogActions,
  } from "@mui/material";
  import React, { useState, useEffect, Fragment } from "react";
  import AddCircleIcon from "@mui/icons-material/AddCircle";
  import DeleteIcon from "@mui/icons-material/Delete";
  import AddAttribute from "./AddAtribute";
  import styled from "@emotion/styled";
  
  const StyledFormControl = styled(FormControl)`
  && {
    margin-bottom: 1rem;
  }
  `;
  
  const AddProductAttributeDialog = ({
    openAddProductAttributeDialog,
    setOpenAddProductAttributeDialog,
    productAttributes,
    handleAddProductAttribute
  }) => {
    const [newProductAttributes, setNewProductAttributes] = useState([
      { name: "", description: "", type: "" },
    ]);
    const [formErrors, setFormErrors] = useState({});
  
    const addNewAttribute = () => {
      setNewProductAttributes([
        ...newProductAttributes,
        { name: "", description: "", type: "" },
      ]);
    };

    console.log("FormErrors");
    console.log(formErrors)
    console.log(formErrors['type1'])
    console.log(newProductAttributes);
  
    const handleAttributeValidation = () => {
      const errors = {};
  
      newProductAttributes.forEach((attribute, index) => {
        if (attribute.name === "") {
          errors[`name${index}`] = "Le nom de l'attribut est requis";
        }
  
        if (attribute.description === "") {
          errors[`description${index}`] =
            "La description de l'attribut est requise";
        }
  
        if (attribute.type === "") {
          errors[`type${index}`] = "Le type de l'attribut est requis";
        }
      });
  
      setFormErrors(errors);
  
      return Object.keys(errors).length === 0;
    };
  
    const handleAddProductAttributeClick = () => {
      if (handleAttributeValidation()) {
        // Perform the API call to add the product attributes here
        handleAddProductAttribute(newProductAttributes);
      }
    };
  
    return (
      <Dialog
        open={openAddProductAttributeDialog}
        onClose={() => setOpenAddProductAttributeDialog(false)}
      >
        <DialogTitle>
          Ajouter un autre attribut
        </DialogTitle>
        <DialogContent sx={{minWidth: 400}}>
          <StyledFormControl fullWidth >
            <InputLabel id="existing-attributes">Liste des attributs existants</InputLabel>
            <Select labelId="existing-attributes" label="Liste des attributs existants" fullWidth>
              {productAttributes.map(
                (productAttribute) => (
                  <MenuItem key={productAttribute.id} value={productAttribute.id}>
                    {productAttribute.name}
                  </MenuItem>
                )
              )}
            </Select>
          </StyledFormControl>
  
          {newProductAttributes.map((attribute, index) => (
            
            attribute && (
                <Fragment key={index}>
                  <AddAttribute
                    index={index}
                    attribute={attribute}
                    setAttribute={(updatedAttribute) => {
                      const newAttributes = [...newProductAttributes];
                      newAttributes[index] = updatedAttribute;
                      setNewProductAttributes(newAttributes);
                    }}
                    formErrors={formErrors}
                  />
                  <IconButton onClick={() => {
                    const newAttributes = [...newProductAttributes];
                    newAttributes.splice(index, 1);
                    setNewProductAttributes(newAttributes);
                  }}>
                    <DeleteIcon />
                  </IconButton>
                </Fragment>
              )
          ))}
  
          <Tooltip title="Ajouter un autre attribut">
            <IconButton onClick={addNewAttribute}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
  
          <DialogActions>
            <Button onClick={() => setOpenAddProductAttributeDialog(false)}>
              Annuler
            </Button>
            <Button
              onClick={handleAddProductAttributeClick}
              color="primary"
              variant="contained"
            >
              Ajouter
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default AddProductAttributeDialog;