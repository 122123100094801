import { Dialog, DialogTitle, DialogContent, Typography, Box, Stack, Divider } from "@mui/material";
import { useLocalStorage } from "domain/hooks/useLocalStorage";
import React from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const Illustration = styled.img`
  width: 100%;
  height: 140px;
  object-fit: cover;
`;


const CategoryLayout = ({}) => {
  const [productCategory, setProductCategory] = useLocalStorage(
    "productCategory",
    null
  );
  const navigate = useNavigate();

  React.useEffect(() => {
    console.log("The productCategory is ", productCategory);
    if (productCategory == null) {
      console.error("Category can't be null");
      navigate(-1);
    }
  }, productCategory);

  const placeholderImageURL =
    "https://images.pexels.com/photos/15624409/pexels-photo-15624409/free-photo-of-boire-nettoyer-liquide-clair.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
  const mainIllustrationURL = productCategory.main_illustration
    ? productCategory.main_illustration.download_url
    : placeholderImageURL;

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={true}
        // @ts-ignore
        onClose={() => navigate(-1)}
        style={{userSelect: "none"}}
      >
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "24px" }}>
          Détails de la catégorie
        </DialogTitle>
        <DialogContent>
          <Stack spacing={4} direction="row" divider={<Divider orientation="vertical" flexItem />}>
            <aside style={{minWidth: "300px"}}>
              <Box>
                <Illustration
                  src={mainIllustrationURL}
                  alt="illustration"
                />
              </Box>
              <h2>Nom</h2>
              <Typography>{productCategory.name}</Typography>
              <h2>Description</h2>
              <Typography>{productCategory.description}</Typography>
            </aside>
            {/* <VDivider /> */}
            <aside style={{ flexGrow: 1 }}>
              <Outlet />
            </aside>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CategoryLayout;
