// @ts-nocheck

import * as React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import RoutesName from "domain/data/RoutesName";
import AdminSpacePage from "ui/pages/AdminSpacePage";
import ArticlesPage from "ui/pages/ProductPage";
import CommentsPage from "ui/pages/CommentsPage";
import DashboardLayout from "ui/pages/DashboardLayout";
import CategoryLayout from "ui/pages/category/CategoryLayout";
import CategoryPage from "ui/pages/CategoryPage";
import HomePage from "ui/pages/HomePage";
import LoginPage from "ui/pages/LoginPage";
import MessagesPage from "ui/pages/MessagesPage";
import NotificationsPage from "ui/pages/NotificationsPage";
import Page404 from "ui/pages/Page404";
import PasswordRecoveryPage from "ui/pages/PasswordRecoveryPage";
import UserCommentPage from "ui/pages/UserCommentPage";
import UserProductPage from "ui/pages/UserProductPage";
import UserListPage from "ui/pages/UserListPage";
import UserPage from "ui/pages/UserPage";
import AttributePage from "ui/pages/AttributePage";
import { ProtectedRoute } from "ui/components/ProtectedRoute";
import CategoryHome from "ui/pages/category/CatetgoryHome";
import SubCategoryPage from "ui/pages/category/SubCategoryPage";
import ProductPage from "ui/pages/category/ProductPage";
import FaqPage from "ui/pages/FaqPage";
import FaqAddEditPage from "ui/pages/FaqAddEditPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path={RoutesName.home}
          element={<Navigate to={RoutesName.login} />}
        />
        <Route exact path={RoutesName.login} element={<LoginPage />} />
        <Route
          exact
          path={RoutesName.forgotPassword}
          element={<PasswordRecoveryPage />}
        />
        <Route
          exact
          path={RoutesName.dashboard}
          element={
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route
            index
            exact
            path={RoutesName.home}
            element={<Navigate to={RoutesName.dashboardHome} />}
          />

          <Route exact path={RoutesName.faq.all} element={<FaqPage />} />
          <Route exact path={RoutesName.faq.add} element={<FaqAddEditPage />} />
          <Route exact path={RoutesName.faq.edit} element={<FaqAddEditPage />} />

          <Route
            index
            exact
            path={RoutesName.dashboardHome}
            element={<HomePage />}
          />

          <Route exact path={RoutesName.heading} element={<CategoryPage />}>
            <Route
              // @ts-ignore
              exact
              path={RoutesName.category.layout}
              element={<CategoryLayout />}
            >
              <Route
                index
                exact
                path={RoutesName.category.home}
                element={<CategoryHome />}
              />

              <Route
                index
                exact
                path={RoutesName.category.subCategory}
                element={<SubCategoryPage />}
              />

              <Route
                index
                exact
                path={RoutesName.category.products}
                element={<ProductPage />}
              />
            </Route>
          </Route>
          <Route
            exact
            path={RoutesName.subheading}
            element={<AttributePage />}
          />
          <Route exact path={RoutesName.articles} element={<ArticlesPage />} />

          <Route exact path={RoutesName.comments} element={<CommentsPage />} />

          <Route exact path={RoutesName.users.home} element={<UserPage />} />
          <Route exact path={RoutesName.users.list} element={<UserListPage />} />
          <Route exact path={RoutesName.users.products} element={<UserProductPage />} />
          <Route exact path={RoutesName.users.comments} element={<UserCommentPage />} />

          <Route exact path={RoutesName.messages} element={<MessagesPage />} />

          <Route
            exact
            path={RoutesName.notifications}
            element={<NotificationsPage />}
          />
          <Route
            exact
            path={RoutesName.adminSpace}
            element={<AdminSpacePage />}
          />
        </Route>
        <Route exact path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
