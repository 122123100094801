import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  TableContainer,
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  DialogActions,
  Fab,
  SpeedDialIcon,
  SpeedDial,
  SpeedDialAction,
  Backdrop,
} from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCategoryDialog from "ui/components/AddCategoryDialog";
import AttributeDialog from "ui/components/AttributeDialog";
import ConfirmDialog from "ui/components/ConfirmDialog";
import EditCategoryDialog from "ui/components/EditCategoryDialog";
import { useApi } from "domain/hooks/useApi";
import Swal from "sweetalert2";
import { showLoadingAlert } from "ui/utils/utils";
import AddSubCategoryDialog from "ui/components/AddSubCategoryDialog";
import { Outlet, useNavigate } from "react-router-dom";
import RoutesName from "domain/data/RoutesName";
import { RemoveRedEye } from "@mui/icons-material";
import { useLocalStorage } from "domain/hooks/useLocalStorage";

export default function CategoryPage() {
  const [productCategories, setProductCategories] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState(null);
  const [selectedAttributeId, setSelectedAttributeId] = useState(null);
  const [editProductCategory, setEditProductCategory] = useState(null);
  const [newProductCategory, setNewProductCategory] = useState(null);
  const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState(false);
  const [
    openConfirmRemoveAttributeDialog,
    setOpenConfirmRemoveAttributeDialog,
  ] = useState(false);
  const [openConfirmAddAttributeDialog, setOpenConfirmAddAttributeDialog] =
    useState(false);
  const [openAttributeDialog, setOpenAttributeDialog] = useState(false);
  const [openConfirmAddCategoryDialog, setOpenConfirmAddCategoryDialog] =
    useState(false);
  const [openConfirmDeleteCategoryDialog, setOpenConfirmDeleteCategoryDialog] =
    useState(false);
  const [openEditCategoryDialog, setOpenEditCategoryDialog] = useState(false);
  const [openConfirmEditCategoryDialog, setOpenConfirmEditCategoryDialog] =
    useState(false);
  const [attributes, setAttributes] = useState([]);
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [subCategory, setSubCategory] = useState(null);
  const [openAddSubCategoryDialog, setOpenAddSubCategoryDialog] =
    useState(false);

  const [openConfirmAddSubCategoryDialog, setOpenConfirmAddSubCategoryDialog] =
    useState(false);

  const {
    ProductCategoryRequests,
    ProductAttributeRequests,
    ProductSubCategoryRequests,
  } = useApi();
  const navigate = useNavigate();

  const [savedCategory, setSavedCategory] = useLocalStorage(
    "productCategory",
    null
  );

  React.useEffect(() => {
    loadProductCategories();
    loadProductAttributes();
  }, []);

  /* React.useEffect(()=>{
    navigate(RoutesName.category.home)
  }) */

  const loadProductCategories = async () => {
    showLoadingAlert();
    ProductCategoryRequests.all()
      .then((it) => {
        setProductCategories(it);
        Swal.close();
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          text: "Une erreur est survenue en rechargeant la page",
        });
        throw e;
      });
  };

  const loadProductAttributes = async () => {
    let data = await ProductAttributeRequests.getAll();
    console.log("Attributes");
    console.log(data);
    setAttributes(data);
  };

  const handleAddCategory = (newCategory) => {
    // Submit form if there are no errors
    setNewProductCategory(newCategory);
    setOpenConfirmAddCategoryDialog(true);
  };

  const handleConfirmAddCategoryClick = async () => {
    let formData = new FormData();
    formData.append("icon", newProductCategory.icon);
    formData.append("name", newProductCategory.name);
    formData.append("description", newProductCategory.description);
    if (newProductCategory.main_illustration) {
      formData.append(
        "main_illustration",
        newProductCategory.main_illustration
      );
    }

    setOpenConfirmAddCategoryDialog(false);
    showLoadingAlert();

    try {
      // @ts-ignore
      await ProductCategoryRequests.register({
        data: formData,
      });
      Swal.fire({
        text: "Votre ajout a été enregistrée avec succès",
        icon: "success",
      }).then(async () => {
        await loadProductCategories();
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant d'enregistrer",
      });
    }
  };

  const handleConfirmEditCategory = async () => {
    setOpenConfirmEditCategoryDialog(false);
    let formData = new FormData();
    formData.append("name", editProductCategory.name);
    formData.append("description", editProductCategory.description);
    if (editProductCategory.main_illustration) {
      formData.append(
        "main_illustration",
        editProductCategory.main_illustration
      );
    }

    console.log("updating");
    console.log(formData);
    console.log("Illustration");
    console.log(editProductCategory.main_illustration);

    // @ts-ignore
    showLoadingAlert();

    try {
      // @ts-ignore
      await ProductCategoryRequests.updateProductCategory({
        productCategoryId: selectedProductCategory.id,
        data: formData,
      });
      Swal.fire({
        text: "Votre modification a été enregistrée avec succès",
        icon: "success",
      }).then(async () => {
        await loadProductCategories();
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant d'enregistrer",
      });
    }

    // close the dialog
  };

  console.log("Debuggingg");
  console.log(productCategories);

  const handleEditCategory = (formValues) => {
    setEditProductCategory(formValues);
    setOpenConfirmEditCategoryDialog(true);
  };

  const handleAddProductAttribute = (attributeId) => {
    setSelectedAttributeId(attributeId);
    setOpenConfirmAddAttributeDialog(true);
  };

  const handleAddAttribute = (productCategory) => {
    setSelectedProductCategory(productCategory);
    if (!attributes || !attributes.length)
      Swal.fire({
        icon: "error",
        text: "Aucun attribut n'a encore été créé",
      });
    else setOpenAttributeDialog(true);
  };

  const handleEditCategoryClick = (productCategory) => {
    setSelectedProductCategory(productCategory);
    setOpenEditCategoryDialog(true);
  };

  const handleDeleteCategoryClick = (productCategory) => {
    setSelectedProductCategory(productCategory);
    setOpenConfirmDeleteCategoryDialog(true);
  };

  const handleConfirmDeleteCategoryClick = async () => {
    // make api call
    setOpenConfirmDeleteCategoryDialog(false);

    try {
      // @ts-ignore
      await ProductCategoryRequests.deleteProductCategory({
        productCategoryId: selectedProductCategory.id,
      });
      let filtered = productCategories.filter(
        (it) => it.id != selectedProductCategory.id
      );
      setProductCategories(filtered);
      setSelectedProductCategory(null);
      // await loadProductCategories()
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant de supprimer la catégorie.",
      });
    }
  };

  const handleConfirmAddAttribute = async () => {
    let data = {
      product_category_id: selectedProductCategory.id,
      product_attributes_ids: [selectedAttributeId],
    };
    // @ts-ignore

    console.log("The data to saved is ", data);

    // @ts-ignore
    showLoadingAlert();

    try {
      // @ts-ignore
      await ProductCategoryRequests.addProductAttribute({ data: data });
      Swal.fire({
        text: "Votre modification a été enregistrée avec succès",
        icon: "success",
      }).then(async () => {
        showLoadingAlert();
        await loadProductCategories();
        setSelectedAttributeId(null);
        setSelectedProductCategory(null);
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant d'enregistrer",
      });
    }

    setOpenConfirmAddAttributeDialog(false);
  };

  const handleRemoveProductAttribute = (attributeId) => {
    setSelectedAttributeId(attributeId);
    setOpenConfirmRemoveAttributeDialog(true);
  };

  const handleConfirmRemoveProductAttribute = async () => {
    let data = {
      product_category_id: selectedProductCategory.id,
      product_attributes_ids: [selectedAttributeId],
    };
    // @ts-ignore
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
    console.log("The data to saved is ", data);
    // @ts-ignore
    showLoadingAlert();

    try {
      // @ts-ignore
      await ProductCategoryRequests.removeProductAttributes({ data: data });
      Swal.fire({
        text: "Votre modification a été enregistrée avec succès",
        icon: "success",
      }).then(async () => {
        showLoadingAlert();
        await loadProductCategories();
        setSelectedAttributeId(null);
        setSelectedProductCategory(null);
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant d'enregistrer",
      });
    }
    setOpenConfirmRemoveAttributeDialog(false);
  };

  const handleAddSubCategory = (subCategory) => {
    setSubCategory(subCategory);
    setOpenConfirmAddSubCategoryDialog(true);
  };

  const handleConfirmAddSubCategoryClicked = async () => {
    let data = {
      product_category_id: selectedProductCategory.id,
      name: subCategory.name,
    };

    // @ts-ignore
    showLoadingAlert();

    try {
      // @ts-ignore
      await ProductSubCategoryRequests.register({ data: data });
      Swal.fire({
        text: "Votre ajout a été enregistré avec succès",
        icon: "success",
      }).then(async () => {
        await loadProductCategories();
        setSubCategory(null);
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant d'enregistrer",
      });
    }

    setOpenConfirmAddSubCategoryDialog(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Typography variant="h5" component="h2" align="center">
          Liste des catégories
        </Typography>
        {productCategories.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Illustration</TableCell>
                <TableCell>Nombre de produits</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productCategories.map((productCategory) => (
                <TableRow
                  key={productCategory.id}
                  selected={
                    selectedProductCategory &&
                    selectedProductCategory.id === productCategory.id
                  }
                  onClick={() => {
                    setSelectedProductCategory(productCategory);
                  }}
                >
                  <TableCell>{productCategory.name}</TableCell>
                  <TableCell>{productCategory.description}</TableCell>
                  <TableCell>
                    {productCategory.main_illustration ? (
                      <img
                        src={productCategory.main_illustration.download_url}
                        alt="Illustration"
                        width="50"
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>{productCategory.products.length}</TableCell>
                  <TableCell>
                    <Tooltip title="Voir la catégorie">
                      <IconButton
                        onClick={() => {
                          setSavedCategory(productCategory);
                          navigate(RoutesName.category.layout);
                        }}
                      >
                        <RemoveRedEye />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ajouter un attribut">
                      <IconButton
                        onClick={() => handleAddAttribute(productCategory)}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Editer la catégorie">
                      <IconButton
                        onClick={() => handleEditCategoryClick(productCategory)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer la catégorie">
                      <IconButton
                        onClick={() =>
                          handleDeleteCategoryClick(productCategory)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography ml={2} variant="body1">
            Aucune catégorie n'est encore créée
          </Typography>
        )}
      </TableContainer>

      {/* <Fab
        color="primary"
        aria-label="add"
        onClick={() => setOpenAddCategoryDialog(true)}
        sx={{ position: "fixed", bottom: "10px", right: "10px" }}
      >
        <AddIcon />
      </Fab> */}
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "fixed", bottom: 10, right: 10 }}
        icon={<SpeedDialIcon />}
        onClose={() => setOpenSpeedDial(false)}
        onOpen={() => setOpenSpeedDial(true)}
        open={openSpeedDial}
      >
        <SpeedDialAction
          key={"addCategory"}
          icon={<AddIcon />}
          tooltipTitle="Catégorie"
          tooltipOpen
          onClick={() => setOpenAddCategoryDialog(true)}
          style={{ whiteSpace: "nowrap", maxWidth: "max-content" }}
        />

        {selectedProductCategory && (
          <SpeedDialAction
            key={"addSubCategory"}
            icon={<AddIcon />}
            tooltipTitle="Sous Catégorie"
            tooltipOpen
            onClick={() => setOpenAddSubCategoryDialog(true)}
            style={{ whiteSpace: "nowrap", maxWidth: "max-content" }}
          />
        )}

        {selectedProductCategory && (
          <SpeedDialAction
            key={"addAttributes"}
            icon={<AddIcon />}
            tooltipTitle="Attributs"
            tooltipOpen
            onClick={() => setOpenAddSubCategoryDialog(true)}
            style={{ whiteSpace: "nowrap", maxWidth: "max-content" }}
          />
        )}
      </SpeedDial>

      {/* Dialog for adding a new category */}
      <AddCategoryDialog
        openAddCategoryDialog={openAddCategoryDialog}
        setOpenAddCategoryDialog={setOpenAddCategoryDialog}
        handleAddCategory={handleAddCategory}
      />
      {/* Dialog for adding an attribute */}
      {selectedProductCategory && (
        <AttributeDialog
          openAttributeDialog={openAttributeDialog}
          setOpenAttributeDialog={setOpenAttributeDialog}
          selectedProductCategory={selectedProductCategory}
          attributes={attributes}
          handleRemoveAttribute={handleRemoveProductAttribute}
          handleAddProductAttribute={handleAddProductAttribute}
        />
      )}

      {/* Edit category dialog */}
      {selectedProductCategory && (
        <EditCategoryDialog
          openEditCategoryDialog={openEditCategoryDialog}
          setOpenEditCategoryDialog={setOpenEditCategoryDialog}
          handleEditCategory={handleEditCategory}
          selectedCategory={selectedProductCategory}
        />
      )}

      <AddSubCategoryDialog
        openAddSubCategoryDialog={openAddSubCategoryDialog}
        setOpenAddSubCategoryDialog={setOpenAddSubCategoryDialog}
        handleOnAction={handleAddSubCategory}
      />

      {/* Conrim addCategoryDialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmAddCategoryDialog}
        setOpenConfirmDialog={setOpenConfirmAddCategoryDialog}
        handleCancelClick={() => {
          setOpenConfirmAddCategoryDialog(false);
        }}
        handleConfirmClick={handleConfirmAddCategoryClick}
        title={"Êtes-vous sur ?"}
        content={"Voulez-vous enregistrer cette catégorie ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      {/* Confirm deleteCategory dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmDeleteCategoryDialog}
        setOpenConfirmDialog={setOpenConfirmDeleteCategoryDialog}
        handleCancelClick={() => {
          setOpenConfirmDeleteCategoryDialog(false);
        }}
        handleConfirmClick={handleConfirmDeleteCategoryClick}
        title={"Êtes-vous sur ?"}
        content={"Voulez-vous supprimer cette catégorie ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      {/* Confirm editCategory dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmEditCategoryDialog}
        setOpenConfirmDialog={setOpenConfirmEditCategoryDialog}
        handleCancelClick={() => {
          setOpenConfirmEditCategoryDialog(false);
        }}
        handleConfirmClick={handleConfirmEditCategory}
        title={"Enregistrer les changements"}
        content={"Voulez-vous enregistrer vos changements ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      {/* Confirm add attribute to Category dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmAddAttributeDialog}
        setOpenConfirmDialog={setOpenConfirmAddAttributeDialog}
        handleCancelClick={() => {
          setOpenConfirmAddAttributeDialog(false);
        }}
        handleConfirmClick={handleConfirmAddAttribute}
        title={"Enregistrer les changements"}
        content={"Voulez-vous ajouter cet attribut ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      {/* Confirm remove attribute to Category dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmRemoveAttributeDialog}
        setOpenConfirmDialog={setOpenConfirmRemoveAttributeDialog}
        handleCancelClick={() => {
          setOpenConfirmRemoveAttributeDialog(false);
        }}
        handleConfirmClick={handleConfirmRemoveProductAttribute}
        title={"Enregistrer les changements"}
        content={"Voulez-vous supprimer cet attribut ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      <ConfirmDialog
        openConfirmDialog={openConfirmAddSubCategoryDialog}
        setOpenConfirmDialog={setOpenConfirmAddSubCategoryDialog}
        handleCancelClick={() => setOpenConfirmAddSubCategoryDialog(false)}
        handleConfirmClick={handleConfirmAddSubCategoryClicked}
        title={"Enregistrer les changements"}
        content={"Voulez-vous enregistrer vos modifications"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      <Outlet />
    </>
  );
}
