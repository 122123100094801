import RoutesName from "domain/data/RoutesName";
import { useAuth } from "domain/hooks/useAuth";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate()
  if (!user) {
    // user is not authenticated
    return navigate(RoutesName.login)
  }
  return children;
};