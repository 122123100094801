import ImageTool from "@editorjs/image";

class MyImageTool extends ImageTool {
  removed() {
    let url = this._data.file.url;
    const parts = url.split("/");

    // Get the last part of the split URL, which is the image file name
    const filename = parts[parts.length - 1];
    fetch(`https://preview.lecobaye.fr/api/editorJs/image/delete/${filename}`, {
      method: "DELETE",
    })
  }
}

export default MyImageTool;
