import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  DialogActions,
  List,
  Divider,
} from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import styled from "@emotion/styled";

const StyledFormControl = styled(FormControl)`
  && {
    margin-bottom: 1rem;
  }
`;

const AttributeDialog = ({
  openAttributeDialog,
  setOpenAttributeDialog,
  selectedProductCategory,
  attributes,
  handleAddProductAttribute,
  handleRemoveAttribute,
}) => {
  

  return (
    <Dialog
      open={openAttributeDialog}
      onClose={() => setOpenAttributeDialog(false)}
    >
      <DialogTitle>
        Ajouter un attribut à {selectedProductCategory?.name}
      </DialogTitle>
      <DialogContent sx={{minWidth: 400}}>
        <StyledFormControl fullWidth>
          <InputLabel>Attributs enregistrés</InputLabel>
          <Select>
            {selectedProductCategory?.product_attributes.map(
              (productAttribute) => (
                <MenuItem key={productAttribute.id} value={productAttribute.id}>
                  {productAttribute.name}
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => handleRemoveAttribute(productAttribute.id)}
                      edge="end"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </MenuItem>
              )
            )}
          </Select>
        </StyledFormControl>
        <List>
          {attributes.map((attribute) => (
            <div style={{marginBottom: "10px"}}>
            <ListItem key={attribute.id}>
              <ListItemText primary={attribute.name} />
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleAddProductAttribute(attribute.id)}>
                  <AddIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            </div>
          ))}
        </List>

        <DialogActions>
          <Button onClick={() => setOpenAttributeDialog(false)}>Fermer</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AttributeDialog;
