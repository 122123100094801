import {
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAttribute from "./AddAtribute";
import styled from "@emotion/styled";

const StyledFormControl = styled(FormControl)`
  && {
    margin-bottom: 1rem;
  }
`;

const EditProductAttributeDialog = ({
  openEditProductAttributeDialog,
  setOpenEditProductAttributeDialog,
  productAttributes,
  productAttribute,
  handleEditProductAttribute,
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    name: productAttribute.name,
    description: productAttribute.description,
    type: productAttribute.type,
  });


  const handleAttributeValidation = () => {
    const errors = {};

    if (formValues.name === "") {
      errors[`name0`] = "Le nom de l'attribut est requis";
    }

    if (formValues.description === "") {
      errors[`description0}`] = "La description de l'attribut est requise";
    }

    if (formValues.type === "") {
      errors[`type0`] = "Le type de l'attribut est requis";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleEditProductAttributeClick = () => {
    if (handleAttributeValidation()) {
      handleEditProductAttribute(formValues);
    }
  };

  return (
    <Dialog
      open={openEditProductAttributeDialog}
      onClose={() => setOpenEditProductAttributeDialog(false)}
    >
      <DialogTitle>Edition de l'attribut</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>

        <AddAttribute
          index={0}
          attribute={{...formValues}}
          setAttribute={(updatedAttribute) => {
            setFormValues(updatedAttribute);
          }}
          formErrors={formErrors}
        />

        <DialogActions>
          <Button onClick={() => setOpenEditProductAttributeDialog(false)}>
            Annuler
          </Button>
          <Button
            onClick={handleEditProductAttributeClick}
            color="primary"
            variant="contained"
          >
            Ajouter
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EditProductAttributeDialog;
