import {
  Chip,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

const ProductsComponent = ({
  handleEditProductClick,
  handleViewProductClick = null,
  handleDeleteProductClick,
  products,
}) => {
  const [selectedProduct2, setSelectedProduct2] = React.useState(null);

  return (
    <>
      <TableContainer component={Paper}>
        {products.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Illustration</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Mots clés</TableCell>
                <TableCell>Likes</TableCell>
                <TableCell>Dislikes</TableCell>
                <TableCell>Commentaires</TableCell>
                <TableCell sx={{}}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow
                  key={product.id}
                  selected={
                    selectedProduct2 && selectedProduct2.id === product.id
                  }
                  onClick={() => {
                    setSelectedProduct2(product);
                  }}
                >
                  <TableCell>
                    <img
                      src={
                        product.main_illustration
                          ? product.main_illustration.download_url
                          : "https://images.pexels.com/photos/15624409/pexels-photo-15624409/free-photo-of-boire-nettoyer-liquide-clair.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      }
                      alt={product.name}
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  </TableCell>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>
                    {product.description}
                  </TableCell>
                  <TableCell>
                    {product.tags.map((tag) => (
                      <Chip key={tag.id} label={tag.name} />
                    ))}
                  </TableCell>
                  <TableCell>{product.likes.length}</TableCell>
                  <TableCell>{product.dislikes.length}</TableCell>
                  <TableCell>{product.comments.length}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEditProductClick(product)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteProductClick(product)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography ml={2} variant="body1">
            Aucun produit n'est encore créé
          </Typography>
        )}
      </TableContainer>
    </>
  );
};

export default ProductsComponent;
