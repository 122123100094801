import { Button, Card, Stack } from "@mui/material";
import * as React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import RoutesName from "domain/data/RoutesName";

const StyledCard = styled(Card)`
  width: 220px;
  height: 120px;
  padding: 10px;
  cursor: pointer;
`;

const StyledButton = styled(Button) `
    width: 220px;
    max-width: 220px;
    height: 120px;
    cursor: pointer;
    text-transform: capitalize;
    text-align: left;
`

export default function UserPage() {

    const navigate = useNavigate()

  return (
    <>
      <Stack direction="row" spacing={2}>
        <StyledButton variant="outlined" color="success" onClick={()=>navigate(RoutesName.users.list2)}>Liste des utilisateurs</StyledButton>
        <StyledButton variant="outlined" color="warning" onClick={()=>navigate(RoutesName.users.comments2)}>Commentaires des utilisateurs</StyledButton>
        <StyledButton variant="outlined" color="secondary" onClick={()=>navigate(RoutesName.users.products2)}>Produits des utilisateurs</StyledButton>
      </Stack>
    </>
  );
}
