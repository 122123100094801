import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions,
    DialogContentText,
  } from "@mui/material";
  import React, { useState, useEffect, Fragment } from "react";

const ConfirmDialog = ({openConfirmDialog, setOpenConfirmDialog, handleCancelClick, handleConfirmClick, title, content, cancelText, confirmText}) => {
    return (
      <Dialog
      open={openConfirmDialog}
      onClose={() => setOpenConfirmDialog(false)}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancelClick()}>{cancelText}</Button>
        <Button onClick={() => handleConfirmClick()}>{confirmText}</Button>
      </DialogActions>
    </Dialog>
    )
    
}

export default ConfirmDialog;