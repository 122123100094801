import React, { useRef } from "react";
import styled from "@emotion/styled";
import ReactDOM from "react-dom";

const StyledInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label`
  display: inline-block;
  padding: 0.5rem 1rem;
  align-self: start;
  color: #fff;
  background-color: #1976d2;
  border-radius: 4px;
  cursor: pointer;
`;

const StyledFileIcon = styled.span`
  margin-right: 0.5rem;
`;

const StyledSelectedPhoto = styled.div`
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  & button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    background-color: black;

    border: none;
    border-radius: 50%;
    font-size: 16px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    color: white;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & button:hover {
    background-color: rgba(0, 0, 0, 0.589);
    color: white;
  }
`;

const StyledSelectedPhotos = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

// @ts-ignore
const ImageInput = ({
  onFileSelect = null,
  onChange = null,
  style = null,
  content = "Choose file",
  multiple = false,
  ...props
}) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;

    if (onFileSelect) {
      if (!multiple) onFileSelect(files[0]);
      else onFileSelect(files);
    }
    if (onChange) onChange(event);
  };

  return (
    <>
      <StyledInput
        type="file"
        accept="image/*"
        multiple={multiple}
        ref={fileInputRef}
        {...props}
        onChange={handleFileSelect}
      />
      <StyledLabel style={style} onClick={handleButtonClick}>
        <StyledFileIcon className="material-icons"></StyledFileIcon>
        {content}
      </StyledLabel>
    </>
  );
};

const SelectedPhotos = ({
  photos = [],
  onRemoveFileAt = null,
  url = false,
  ...props
}) => {
  const previewContainerRef = useRef(null);

  const removeFileAt = (index) => {
    if (onRemoveFileAt) onRemoveFileAt(index);
  };

  const buildSelectedPhotos = () => {
    return photos.map((file, index) => {
      const key = url ? file : URL.createObjectURL(file);

      return (
        <StyledSelectedPhoto
          key={key}
          style={{ backgroundImage: `url(${key})` }}
        >
          <button
            onClick={() => {
              removeFileAt(index);
            }}
          >
            <span className="material-icons">close</span>
          </button>
        </StyledSelectedPhoto>
      );
    });
  };

  React.useEffect(() => {
    return () => {
      if (!url) {
        for (let index = 0; index < photos.length; index++) {
          const file = photos[index];
          URL.revokeObjectURL(file);
        }
      }
    };
  }, [photos, url]);

  return (
    <StyledSelectedPhotos {...props} ref={previewContainerRef}>
      {buildSelectedPhotos()}
    </StyledSelectedPhotos>
  );
};

export { ImageInput, StyledLabel, StyledInput, SelectedPhotos };
