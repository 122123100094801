
export default function authHeader(apiKey) {

    // eslint-disable-next-line react-hooks/rules-of-hooks


    if (apiKey) {
        // for Node.js Express back-end
        return { Authorization: `Bearer ${apiKey}` };
    } else {
        return {};
    }
}