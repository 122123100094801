import { Typography }from "@mui/material"
import * as React from 'react'

const InputError = ({messages = []}) => (
    <div>
        {messages.length > 0 && (
            <>
                {messages.map((message, index) => (
                    <Typography
                        key={index}
                        mt={2}
                        sx= {{color: "rgb(248 113 113)"}}
                        >
                        {message}
                    </Typography>
                ))}
            </>
        )}
    </div>
)

export default InputError