import {
  Box,
  Button,
  Fab,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useLocalStorage } from "domain/hooks/useLocalStorage";
import React from "react";
import { ArrowBack, ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { showLoadingAlert } from "ui/utils/utils";
import AddSubCategoryDialog from "ui/components/AddSubCategoryDialog";
import ConfirmDialog from "ui/components/ConfirmDialog";
import { useApi } from "domain/hooks/useApi";

const SubCategoryPage = () => {
  const [productCategory, setProductCategory] = useLocalStorage(
    "productCategory",
    null
  );

  const [subCategory, setSubCategory] = React.useState(null);
  const [openAddSubCategoryDialog, setOpenAddSubCategoryDialog] =
    React.useState(false);

    const [openEditSubCategoryDialog, setOpenEditSubCategoryDialog] =
    React.useState(false);

  const [openConfirmAddSubCategoryDialog, setOpenConfirmAddSubCategoryDialog] =
    React.useState(false);

  const [
    openConfirmEditSubCategoryDialog,
    setOpenConfirmEditSubCategoryDialog,
  ] = React.useState(false);

  const [
    openConfirmDeleteSubCategoryDialog,
    setOpenConfirmDeleteSubCategoryDialog,
  ] = React.useState(false);

  const navigate = useNavigate();

  const [selectedSubCategory, setSelectedSubCategory] = React.useState(null);
  const { ProductCategoryRequests, ProductSubCategoryRequests } = useApi();

  React.useEffect(() => {
    console.log("SubCategory", productCategory);
  });

  const handleEditSubCategoryClick = (subCategory) => {
    setSubCategory(subCategory);
    setOpenConfirmEditSubCategoryDialog(true);
  };

  const handleDeleteSubCategoryClick = (subCategory) => {
    setSubCategory(subCategory);
    setOpenConfirmDeleteSubCategoryDialog(true);
  };

  const handleAddSubCategory = (subCategory) => {
    setSubCategory(subCategory);
    setOpenConfirmAddSubCategoryDialog(true);
  };

  const loadProductCategory = async () => {
    showLoadingAlert();
    // @ts-ignore
    ProductCategoryRequests.getById({productCategoryId: productCategory.id})
      .then((it) => {
        setProductCategory(it);
        Swal.close();
      })
      .catch((e) => {
        console.error(e)
        Swal.fire({
          icon: "error",
          text: "Une erreur est survenue en essayant de recharger la page",
        });
      });
  };

  const handleConfirmAddSubCategoryClicked = async () => {
    let data = {
      product_category_id: productCategory.id,
      name: subCategory.name,
    };

    // @ts-ignore
    showLoadingAlert();

    try {
      // @ts-ignore
      await ProductSubCategoryRequests.register({ data: data });
      Swal.fire({
        text: "Votre ajout a été enregistré avec succès",
        icon: "success",
      }).then(async () => {
        await loadProductCategory();
        setSubCategory(null);
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant d'enregistrer",
      });
    }

    setOpenConfirmAddSubCategoryDialog(false);
  };

  const handleConfirmEditSubCategoryClicked = async () => {
    let data = {
      product_category_id: productCategory.id,
      name: subCategory.name,
    };

    // @ts-ignore
    showLoadingAlert();

    try {
      // @ts-ignore
      await ProductSubCategoryRequests.update({
        subCategoryId: selectedSubCategory.id,
        data: data,
      });
      Swal.fire({
        text: "Votre mise à jour a été effectué avec succès",
        icon: "success",
      }).then(async () => {
        await loadProductCategory();
        setSubCategory(null);
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant de faire la mise à jour",
      });
    }

    setOpenConfirmEditSubCategoryDialog(false);
  };

  const handleConfirmDeleteSubCategoryClicked = async () => {
    // @ts-ignore
    showLoadingAlert();

    try {
      // @ts-ignore
      await ProductSubCategoryRequests.deleteById({
        subCategoryId: subCategory.id,
      });
      Swal.fire({
        text: "Elément supprimé avec succés",
        icon: "success",
      }).then(async () => {
        await loadProductCategory();
        setSubCategory(null);
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant de faire la suppression",
      });
    }

    setOpenConfirmDeleteSubCategoryDialog(false);
  };

  return (
    <Box style={{ position: "relative", height: "100%" }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIos />
        </IconButton>
        <Typography
          sx={{ flexGrow: 1, textAlign: "center" }}
          variant="h5"
          component="h2"
          align="center"
        >
          Liste des sous-catégories
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpenAddSubCategoryDialog(true)}
        >
          Ajouter une sous-catégorie
        </Button>
      </Stack>
      <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
        {productCategory.sub_categories.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Nombre de produits</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productCategory.sub_categories.map((subCategory) => (
                <TableRow
                  key={subCategory.id}
                  selected={
                    selectedSubCategory &&
                    selectedSubCategory.id === subCategory.id
                  }
                  onClick={() => {
                    setSelectedSubCategory(subCategory);
                  }}
                >
                  <TableCell>{subCategory.name}</TableCell>
                  <TableCell>{subCategory.description}</TableCell>
                  <TableCell>{productCategory.products.length}</TableCell>
                  <TableCell>
                    <Tooltip title="Editer la sous-catégorie">
                      <IconButton
                        onClick={() => setOpenEditSubCategoryDialog(true)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer la sous-catégorie">
                      <IconButton
                        onClick={() =>
                          handleDeleteSubCategoryClick(subCategory)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography ml={2} variant="body1">
            Aucune sous-catégorie n'est encore créée
          </Typography>
        )}
      </TableContainer>

      <AddSubCategoryDialog
        openAddSubCategoryDialog={openAddSubCategoryDialog}
        setOpenAddSubCategoryDialog={setOpenAddSubCategoryDialog}
        handleOnAction={handleAddSubCategory}
      />

      <AddSubCategoryDialog
        openAddSubCategoryDialog={openEditSubCategoryDialog}
        setOpenAddSubCategoryDialog={setOpenEditSubCategoryDialog}
        handleOnAction={handleEditSubCategoryClick}
        data = {selectedSubCategory}
        actionText="Modifier"
      />



      <ConfirmDialog
        openConfirmDialog={openConfirmAddSubCategoryDialog}
        setOpenConfirmDialog={setOpenConfirmAddSubCategoryDialog}
        handleCancelClick={() => setOpenConfirmAddSubCategoryDialog(false)}
        handleConfirmClick={handleConfirmAddSubCategoryClicked}
        title={"Enregistrer les changements"}
        content={"Voulez-vous enregistrer vos modifications"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      <ConfirmDialog
        openConfirmDialog={openConfirmEditSubCategoryDialog}
        setOpenConfirmDialog={setOpenConfirmEditSubCategoryDialog}
        handleCancelClick={() => setOpenConfirmEditSubCategoryDialog(false)}
        handleConfirmClick={handleConfirmEditSubCategoryClicked}
        title={"Enregistrer les changements"}
        content={"Voulez-vous mettre à jour cette sous-catégorie"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

<ConfirmDialog
        openConfirmDialog={openConfirmDeleteSubCategoryDialog}
        setOpenConfirmDialog={setOpenConfirmDeleteSubCategoryDialog}
        handleCancelClick={() => setOpenConfirmDeleteSubCategoryDialog(false)}
        handleConfirmClick={handleConfirmDeleteSubCategoryClicked}
        title={"Enregistrer les changements"}
        content={"Voulez-vous supprimer cette sous-catégorie"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />
    </Box>
  );
};

export default SubCategoryPage;
