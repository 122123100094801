import styled from "@emotion/styled";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  InputLabel,
  Select,
  ListItemSecondaryAction,
  MenuItem,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const DialogContentWrapper = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  && {
    margin-bottom: 1rem;
  }
`;

const AddSubCategoryDialog = ({
  openAddSubCategoryDialog,
  setOpenAddSubCategoryDialog,
  handleOnAction,
  data = null,
  actionText = "Ajouter"
}) => {
  const [subCategory, setSubCategory] = useState({
    name: data ? data.name ? data.name : "" : "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
  });

  const handleSubCategoryValidation = () => {
    // Validate form
    let errors = {
      name: "",
    };

    if (!subCategory.name) {
      errors.name = "Le nom est obligatoire";
    }

    setFormErrors(errors);
    return !Object.values(errors).some((error) => error !== "");
  };

  const handleAddSubCategoryClick = () => {
    if (handleSubCategoryValidation()) handleOnAction(subCategory);
  };

  useEffect(()=>{
    setSubCategory({
      name: data ? data.name ? data.name : "" : ""
    })
  }, [data])

  return (
    <>
      <Dialog
        open={openAddSubCategoryDialog}
        onClose={() => setOpenAddSubCategoryDialog(false)}
      >
        <DialogTitle>Ajouter une sous-catégorie</DialogTitle>
        <DialogContentWrapper sx={{ minWidth: 600 }}>
          <TextField
            label="Nom"
            fullWidth
            margin="normal"
            value={subCategory.name}
            onChange={(event) =>
              setSubCategory({ ...subCategory, name: event.target.value })
            }
            error={formErrors.name !== ""}
            helperText={formErrors.name}
          />
        </DialogContentWrapper>
        <Button
          sx={{ margin: "20px" }}
          variant="contained"
          color="primary"
          onClick={() => handleAddSubCategoryClick()}
        >
          {actionText}
        </Button>
      </Dialog>
    </>
  );
};

export default AddSubCategoryDialog;
