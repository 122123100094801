const RoutesName = {
  home: "",
  register: "/register",
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  verifyEmail: "/verify-email",
  verificationNotification: "/email/verification-notification",
  logout: "/logout",
  articles: "articles",
  comments: "comments",
  users: {
    home: "users",
    list: "users/list",
    products: "users/products",
    comments: "users/comments",
    list2: "list",
    products2: "products",
    comments2: "comments"
  },
  messages: "messages",
  notifications: "notifications",
  adminSpace: "admin-space",
  heading: "heading",
  subheading: "subheading",
  faq: {
    all: "faq",
    add: "faq/add",
    edit: "faq/edit",
    add2: "add",
    edit2: "edit",
  },
  dashboard: "/dashboard",
  dashboardHome: "home",
  category: {
    layout: "@",
    home: "",
    subCategory: "sub-category",
    products: "products",
  },
};

export default RoutesName;
