import axios from "domain/lib/axios";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import RoutesName from "domain/data/RoutesName";
import { useLocalStorage } from "./useLocalStorage";
import ApiRoutes from "../data/ApiRoutes";
import authHeader from "../utils/authHeader";

// @ts-ignore
export const useAuth = ({
  // @ts-ignore
  middleware,
  // @ts-ignore
  redirectIfAuthenticated,
  // @ts-ignore
  errorsHandler,
  // @ts-ignore
  globalErrorsHandler,
} = {}) => {
  // @ts-ignore

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [apiKey, setApiKey] = useLocalStorage("apiKey", null);
  const [user, setUser] = useLocalStorage("user", null);

  const processError = (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("An error happened on the server");
      console.error("The HTTP status code is ", error.response.status);
      console.error(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("The request was made but no response was received");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("An unknown error occurred");
      console.error(error);
    }
    if (
      error.response.status === 401 ||
      error.response.status === 419 ||
      error.response.status === 403
    ) {
      console.log("You are not authenticated");
      setUser(null);
      navigate(RoutesName.login);
    }
  };

  const register = async ({
    setErrors,
    onSuccess = null,
    onErrors = null,
    ...props
  }) => {
    setErrors([]);

    axios
      .post(ApiRoutes.auth.register(), props)
      .then((res) => {
        if (onSuccess) onSuccess(res.data);
        return res.data;
      })
      .catch((error) => {
        console.log("An eror occurred");
        console.log(error);
        if (onErrors) onErrors(error);
        if (setErrors) setErrors(error.response.data.errors);
        if (errorsHandler) errorsHandler(error);
        if (globalErrorsHandler) globalErrorsHandler(error);
        if (!onErrors && !setErrors && !errorsHandler) throw error;
      });
  };

  const login = async ({
    setErrors,
    setStatus = null,
    onSuccess = null,
    onErrors = null,
    ...props
  }) => {
    setErrors([]);
    setStatus(null);
    axios
      .post(ApiRoutes.auth.login(), props)
      .then((res) => {
        if (onSuccess) onSuccess(res.data);
        setApiKey(res.data.token);
        setUser(res.data.user);
        return res.data;
      })
      .catch((error) => {
        console.log("An eror occurred");
        console.log(error);
        if (onErrors) onErrors(error);
        if (setErrors) setErrors(error.response.data.errors);
        if (errorsHandler) errorsHandler(error);
        else if (globalErrorsHandler) globalErrorsHandler(error);
        else processError(error);

        if (!onErrors && !setErrors && !errorsHandler) throw error;
      });
  };

  const forgotPassword = async ({
    setErrors,
    setStatus,
    onErrors = null,
    email,
  }) => {
    setErrors([]);
    setStatus(null);

    axios
      .post(RoutesName.forgotPassword, { email })
      .then((response) => setStatus(response.data.status))
      .catch((error) => {
        console.log("An eror occurred");
        console.log(error);
        if (onErrors) onErrors(error);
        if (setErrors) setErrors(error.response.data.errors);
        if (errorsHandler) errorsHandler(error);
        else if (globalErrorsHandler) globalErrorsHandler(error);
        else processError(error);

        if (!onErrors && !setErrors && !errorsHandler) throw error;
      });
  };

  const resetPassword = async ({ setErrors, setStatus, ...props }) => {
    setErrors([]);
    setStatus(null);

    axios
      .post(RoutesName.resetPassword, {
        token: searchParams.get("token"),
        ...props,
      })
      .then((response) =>
        navigate("/login?reset=" + btoa(response.data.status))
      )
      .catch((error) => {
        console.log("An eror occurred");
        console.log(error);
        if (setErrors) setErrors(error.response.data.errors);
        if (errorsHandler) errorsHandler(error);
        else if (globalErrorsHandler) globalErrorsHandler(error);
        else processError(error);

        if (
          !setErrors &&
          !errorsHandler &&
          !globalErrorsHandler &&
          !processError
        )
          throw error;
      });
  };

  const resendEmailVerification = ({ setStatus }) => {
    axios
      .post(RoutesName.verificationNotification)
      .then((response) => setStatus(response.data.status));
  };

  const logout = async () => {
    await axios
      .post(ApiRoutes.auth.logout(), {}, { headers: authHeader(apiKey) })
      .then(() => {
        setUser(null);
        setApiKey(null);
      })
      .catch((error) => {
        if (globalErrorsHandler) globalErrorsHandler(error);
        else if (processError) processError(error);
        if (!errorsHandler && !globalErrorsHandler && !processError)
          throw error;
      });

    window.location.pathname = RoutesName.login;
  };

  useEffect(() => {
    console.log("Use effect called");
    if (middleware === "guest") {
      if (redirectIfAuthenticated && user) navigate(redirectIfAuthenticated);
      else if (!user && isAuthenticableURL()) navigate(RoutesName.login);
    }
    /*if (
            window.location.pathname === RoutesName.verifyEmail &&
            user?.email_verified_at
        )
            navigate(redirectIfAuthenticated)*/

    if (middleware === "auth") logout();
  }, [user, apiKey]);

  const updateData = (userData) => {
    setApiKey(userData.token);
    setUser(userData.user);
  };

  return {
    apiKey,
    user,
    updateData,
    register,
    login,
    forgotPassword,
    resetPassword,
    resendEmailVerification,
    logout,
  };
};

const isAuthenticableURL = () => {
  if (
    !window.location.pathname.toLowerCase().includes(RoutesName.login) ||
    !window.location.pathname.toLowerCase().includes(RoutesName.verifyEmail) ||
    !window.location.pathname
      .toLowerCase()
      .includes(RoutesName.verificationNotification) ||
    !window.location.pathname.toLowerCase().includes(RoutesName.logout) ||
    !window.location.pathname
      .toLowerCase()
      .includes(RoutesName.resetPassword) ||
    !window.location.pathname.toLowerCase().includes(RoutesName.forgotPassword)
  )
    return true;

  return false;
};
