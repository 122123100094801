import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Fab,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { showLoadingAlert } from "ui/utils/utils";
import ConfirmDialog from "ui/components/ConfirmDialog";
import { useApi } from "domain/hooks/useApi";
import RoutesName from "domain/data/RoutesName";
import * as React from "react";
import SendNotificationDialog from "ui/components/SendNotificationDialog";

export default function UserListPage() {
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [openConfirmBanishUserDialog, setOpenConfirmBanishUserDialog] =
    React.useState(false);
  const [openConfirmBanishUsersDialog, setOpenConfirmBanishUsersDialog] =
    React.useState(false);

  const [openSendNotificationDialog, setOpenSendNotificationDialog] =
    React.useState(false);

  const [openSendNotificationsDialog, setOpenSendNotificationsDialog] =
    React.useState(false);

  const [
    openConfirmSendNotificationDialog,
    setOpenConfirmSendNotificationDialog,
  ] = React.useState(false);

  const [
    openConfirmSendNotificationsDialog,
    setOpenConfirmSendNotificationsDialog,
  ] = React.useState(false);

  const [notification, setNotification] = React.useState("");
  const [globaNotification, setGlobalNotification] = React.useState("");

  const { UserRequests } = useApi();
  const navigate = useNavigate();

  React.useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    showLoadingAlert();
    UserRequests.getAll()
      .then((data) => {
        console.log("Users got");
        console.log(data);
        setUsers(data);
        Swal.close();
      })
      .catch((e) => {
        Swal.fire({
          text: "Une erreur est survenue en chargeant les données.",
          icon: "error",
        });
      });
  };

  const handleBanishUserClick = (user) => {
    setSelectedUser(user);
    setOpenConfirmBanishUserDialog(true);
  };

  const handleNotifyUserClick = (text) => {
    setNotification(text);
    setOpenConfirmSendNotificationDialog(true);
  };

  const handleNotifyUsersClick = (text) => {
    setGlobalNotification(text);
    setOpenConfirmSendNotificationsDialog(true);
  };

  const handleBanishUsersClick = () => {
    setOpenConfirmBanishUsersDialog(true);
  };

  const handleToggleUserSelection = (checked, user) => {
    if (checked && !selectedUsers.includes(user)) {
      setSelectedUsers([...selectedUsers, user]);
    } else if (!checked) {
      setSelectedUsers(selectedUsers.filter((it) => it.id != user.id));
    }
  };

  const handleConfirmBanishUserClicked = async () => {
    showLoadingAlert();
    // @ts-ignore
    UserRequests.banishById({ userId: selectedUser.id })
      .then((data) => {
        console.log("The returned data from api is ", data);

        const updatedUsers = users.map((user) =>
          user.id === selectedUser.id
            ? { ...user, banished: data.banished }
            : user
        );
        setOpenConfirmBanishUserDialog(false);
        setUsers(updatedUsers);
        Swal.fire({
          text: "L'utilisateur a été banni avec succès",
          icon: "success",
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Une erreur est survenue en essayant de bannir l'utilisateur",
        });
      });
  };

  const handleConfirmBanishUsersClicked = async () => {
    showLoadingAlert();

    const banishPromises = selectedUsers.map((user) =>
      // @ts-ignore
      UserRequests.banishById({ userId: user.id })
    );

    Promise.all(banishPromises)
      .then(() => {
        const filteredUsers = users.filter(
          (user) => !selectedUsers.includes(user)
        );
        setSelectedUsers([]);
        setOpenConfirmBanishUsersDialog(false);
        setUsers(filteredUsers);
        Swal.fire({
          text: "Les utilisateurs ont été bannis avec succès",
          icon: "success",
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Une erreur est survenue en essayant de bannir les utilisteurs",
        });
      });
  };

  const handleConfirmNotifyUserClicked = async () => {
    showLoadingAlert();
    // @ts-ignore
    UserRequests.notifyById({
      userId: selectedUser.id,
      data: {
        message: notification,
      },
    })
      .then(() => {
        setOpenConfirmSendNotificationDialog(false);
        setOpenSendNotificationDialog(false);
        Swal.fire({
          text: "La  notification a été envoyé avec succès",
          icon: "success",
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Une erreur est survenue en essayant d'envoyer la notification",
        });
      });
  };

  const handleConfirmNotifyUsersClicked = async () => {
    showLoadingAlert();

    const notifyPromises = selectedUsers.map((user) =>
      // @ts-ignore
      UserRequests.notifyById({
        userId: user.id,
        data: {
          message: globaNotification,
        },
      })
    );

    Promise.all(notifyPromises)
      .then(() => {
        setOpenConfirmSendNotificationsDialog(false);
        setOpenSendNotificationsDialog(false);
        Swal.fire({
          text: "Les notifications ont été envoyé avec succès",
          icon: "success",
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Une erreur est survenue en essayant d'envoyer les notifications",
        });
      });
  };

  return (
    <>
      <Box sx={{ userSelect: "none" }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography sx={{ flexGrow: 1 }} variant="h5" component="h2">
            Liste des utilisateurs
          </Typography>

          {selectedUsers.length > 0 && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleBanishUsersClick()}
            >
              <BlockIcon sx={{ marginRight: "10px" }} />
              Bannir
            </Button>
          )}

          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenSendNotificationsDialog(true)}
          >
            <SendIcon sx={{ marginRight: "10px" }} />
            Notifier
          </Button>
        </Stack>
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          {users.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={selectedUsers.length === users.length}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedUsers(users)
                          : setSelectedUsers([])
                      }
                    />
                  </TableCell>
                  <TableCell>Avatar</TableCell>
                  <TableCell>Pseudo</TableCell>
                  <TableCell>Nombre de produits créés</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow
                    key={user.id}
                    selected={
                      selectedUsers.includes(user) ||
                      (selectedUser && selectedUser.id == user.id)
                    }
                    onClick={() => {
                      if (selectedUser && selectedUser.id == user.id)
                        setSelectedUser(null);
                      else setSelectedUser(user);
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        checked={selectedUsers.includes(user)}
                        onChange={(_, checked) =>
                          handleToggleUserSelection(checked, user)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Avatar
                        alt={user.pseudo}
                        src={
                          user.profile_picture
                            ? user.profile_picture.download_url
                            : ""
                        }
                      >
                        {user.pseudo.slice(0, 2).toUpperCase()}
                      </Avatar>
                    </TableCell>
                    <TableCell>
                      {user.pseudo}{" "}
                      {user.banished ? (
                        <Chip label="Banni" color="error" />
                      ) : null}
                    </TableCell>
                    <TableCell>{user.products_count}</TableCell>
                    <TableCell>
                      <Tooltip title="Envoyer une notification à l'utilisateur">
                        <IconButton
                          onClick={() => {
                            setSelectedUser(user);
                            setOpenSendNotificationDialog(true);
                          }}
                        >
                          <SendIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Bannir l'utilisateur">
                        <IconButton onClick={() => handleBanishUserClick(user)}>
                          <BlockIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography ml={2} variant="body1">
              Aucun utilisateur n'a encore créé son compte
            </Typography>
          )}
        </TableContainer>
      </Box>

      <SendNotificationDialog
        openSendNotificationDialog={openSendNotificationsDialog}
        setOpenSendNotificationDialog={setOpenSendNotificationsDialog}
        handleSubmit={handleNotifyUsersClick}
      />

      <SendNotificationDialog
        openSendNotificationDialog={openSendNotificationDialog}
        setOpenSendNotificationDialog={setOpenSendNotificationDialog}
        handleSubmit={handleNotifyUserClick}
      />

      <ConfirmDialog
        openConfirmDialog={openConfirmSendNotificationDialog}
        setOpenConfirmDialog={setOpenConfirmSendNotificationDialog}
        handleCancelClick={() => setOpenConfirmSendNotificationDialog(false)}
        handleConfirmClick={handleConfirmNotifyUserClicked}
        title={"Enregistrer les changements"}
        content={"Voulez-vous envoyer cette notification ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      <ConfirmDialog
        openConfirmDialog={openConfirmSendNotificationsDialog}
        setOpenConfirmDialog={setOpenConfirmSendNotificationsDialog}
        handleCancelClick={() => setOpenConfirmSendNotificationsDialog(false)}
        handleConfirmClick={handleConfirmNotifyUsersClicked}
        title={"Enregistrer les changements"}
        content={"Voulez-vous envoyer ces notifications ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      <ConfirmDialog
        openConfirmDialog={openConfirmBanishUsersDialog}
        setOpenConfirmDialog={setOpenConfirmBanishUsersDialog}
        handleCancelClick={() => setOpenConfirmBanishUsersDialog(false)}
        handleConfirmClick={handleConfirmBanishUsersClicked}
        title={"Enregistrer les changements"}
        content={"Voulez-vous bannir ces utilisateurs ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      <ConfirmDialog
        openConfirmDialog={openConfirmBanishUserDialog}
        setOpenConfirmDialog={setOpenConfirmBanishUserDialog}
        handleCancelClick={() => setOpenConfirmBanishUserDialog(false)}
        handleConfirmClick={handleConfirmBanishUserClicked}
        title={"Enregistrer les changements"}
        content={
          selectedUser && selectedUser.banished
            ? "Voulez-vous débannir cet utilisateur ?"
            : "Voulez-vous bannir cet utilisateur ?"
        }
        cancelText={"Non"}
        confirmText={"Oui"}
      />
    </>
  );
}
