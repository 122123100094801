import * as React from "react";
import AddIcon from "@mui/icons-material/Add";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import faker from "faker";
import { Box, Divider, Fab, Stack, Typography } from "@mui/material";
import AdminSpaceAddUserDialog from "ui/components/AdminSpaceAddUserDialog";
import { useAuth } from "domain/hooks/useAuth";
import { useApi } from "domain/hooks/useApi";
import Swal from "sweetalert2";
import swal from "sweetalert"
import UserTable from "ui/components/AdminUsersTable";
import Admin from "../../domain/data/Admin";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export const chart1Options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Connexions",
    },
  },
};

export const chart2Options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Tickets résolus",
    },
  },
};

const chart1Labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];

export const chart1Data = {
  labels: chart1Labels,
  datasets: [
    {
      label: "Utilisateur 1",
      data: chart1Labels.map(() =>
        faker.datatype.number({ min: -1000, max: 1000 })
      ),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Utilisateur 2",
      data: chart1Labels.map(() =>
        faker.datatype.number({ min: -1000, max: 1000 })
      ),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export const chart2Data = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# tickets",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export default function AdminSpacePage() {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const [users, setUsers] = React.useState([]);

  // @ts-ignore
  const { register, user } = useAuth({
    middleware: "guest",
  });

  const { AdminSpaceRequests, AuthRequests } = useApi();

  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [userType, setUserType] = React.useState(Admin.Type.TYPE_ADMIN);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [errors, setErrors] = React.useState([]);

  const onFabClicked = () => {
    setIsCreateDialogOpen(!isCreateDialogOpen);
  };

  const onCloseDialog = () => {
    setIsCreateDialogOpen(false);
  };

  const onCreateButtonClicked = () => {
    console.log("The data is");
    console.log({
      firstname,
      lastname,
      type: userType,
      email,
      password,
      password_confirmation: passwordConfirmation,
      setErrors,
    });
    AuthRequests.register({
      firstname,
      lastname,
      type: userType,
      email,
      password,
      password_confirmation: passwordConfirmation,
      setErrors,
      onSuccess: () => {
        Swal.fire({
          text: "Votre compte a été créé avec succès",
          icon: "success",
        }).then(() => onCloseDialog());
      },
    });
  };

  React.useEffect(() => {
    loadUsers()
  }, []);

  const loadUsers = ()=>{
    AdminSpaceRequests.allUsers({
      onSuccess: (data) => setUsers(data),
      onErrors: () => {
        Swal.fire({
        title: "Oops...",
        text: "Une erreur est survenue",
        icon: "error"
      })
      },
    });
  }

  const handleEditUser = (user) => {};

  const handleDeleteUser = (user) => {
    AdminSpaceRequests.deleteUser({
      userId: user.id,
      onSuccess: () => loadUsers(),
      onErrors: () => {
        Swal.fire({
        title: "Oops...",
        text: "Une erreur est survenue",
        icon: "error"
      })
      },
    });

  };

  console.log(users);
  console.log(user);

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ width: "100%", height: "400px" }}
      >
        <Pie
          // @ts-ignore
          options={chart2Options}
          data={chart2Data}
          width={200}
        />
        <Line
          // @ts-ignore
          options={chart1Options}
          data={chart1Data}
        />
      </Stack>

      <Stack spacing={10} mt={8}>
        <Box>
          <Typography variant="h5" fontWeight={300}>
            Utilisateurs - SuperAdmin
          </Typography>
          <Divider sx={{ marginTop: "10px" }} />
          <Stack direction="row" spacing={2} mt={2}>
            <UserTable
              users={users.filter((it) => it.type == Admin.Type.TYPE_SUPER_ADMIN)}
              handleDeleteUser={handleDeleteUser}
              handleEditUser={handleEditUser}
            />
          </Stack>
        </Box>

        <Box>
          <Typography variant="h5" fontWeight={300}>
            Utilisateurs - Admin
          </Typography>
          <Divider sx={{ marginTop: "10px" }} />
          <Stack direction="row" spacing={2} mt={2}>
            <UserTable
              users={users.filter((it) => it.type == Admin.Type.TYPE_ADMIN)}
              handleDeleteUser={handleDeleteUser}
              handleEditUser={handleEditUser}
            />
          </Stack>
        </Box>
      </Stack>

      <Fab
        color="primary"
        aria-label="add"
        onClick={onFabClicked}
        sx={{ position: "fixed", bottom: "10px", right: "10px" }}
      >
        <AddIcon />
      </Fab>

      {/* Create Dialog */}
      <AdminSpaceAddUserDialog
        open={isCreateDialogOpen}
        onCreate={onCreateButtonClicked}
        formObject={{
          firstname,
          lastname,
          password,
          passwordConfirmation,
          errors,
          userType,
          setFirstname,
          setLastname,
          setUserType,
          setEmail,
          setPassword,
          setPasswordConfirmation,
        }}
        onClose={onCloseDialog}
      />
    </Box>
  );
}
