import { Divider, Typography } from "@mui/material"
import RoutesName from "domain/data/RoutesName"
import React from "react"

import { useNavigate } from "react-router-dom"
import { useLocalStorage } from "domain/hooks/useLocalStorage"
import styled from "@emotion/styled"

const Items = styled.ul`
    display: flex;
    gap: 10px;
    list-style-type: none;
`

const Item = styled.ul`
    width: 180px;
    height: 100px;
    border-radius: 8px;
    padding: 10px;
    background-color: #cacaca;
    cursor: pointer;
    user-select: none;
`

const ItemTitle = styled.div`
    font-weight: medium;
    font-size: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ItemValue = styled.div`
    font-size: 24px;
    color: #6C3B10;
    font-weight: bold;
    margin-top: 10px;
`




const CategoryHome = () => {

    const [productCategory, setProductCategory] = useLocalStorage('productCategory', null)
    const navigate = useNavigate()

    React.useEffect(()=>{
        console.log("The productCategory is ", productCategory)
        if (productCategory == null) {
            throw Error("Category can't be null")
            navigate(-1)
        }
    }, productCategory)

    

    return (
        <>
        
                <Items>
                    <Item onClick={()=>navigate(RoutesName.category.products)}>
                        <ItemTitle>Produits</ItemTitle>
                        <ItemValue>{productCategory.products.length}</ItemValue>
                    </Item>
                    <Item>
                        <ItemTitle>Attributs</ItemTitle>
                        <ItemValue>{productCategory.product_attributes.length}</ItemValue>
                    </Item>
                    <Item onClick={()=>navigate(RoutesName.category.subCategory)}>
                        <ItemTitle>Sous catégories</ItemTitle>
                        <ItemValue>{productCategory.sub_categories.length}</ItemValue>
                    </Item>
                </Items>
            
        </>
    )
}

export default CategoryHome