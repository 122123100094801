import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextareaAutosize,
} from "@mui/material";
import * as React from "react";

const SendNotificationDialog = ({
  openSendNotificationDialog,
  setOpenSendNotificationDialog,
  handleSubmit,
}) => {
  const [message, setMessage] = React.useState("");

  return (
    <>
      <Dialog
        open={openSendNotificationDialog}
        onClose={() => setOpenSendNotificationDialog(false)}
      >
        <DialogTitle>Envoyer une notification</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}></DialogContent>
        <div style={{ padding: "0 10px" }}>
          <TextareaAutosize
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            style={{
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "200px",
              padding: "10px",
            }}
          ></TextareaAutosize>
        </div>
        <Button
          sx={{ margin: "20px" }}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(message)}
        >
          Envoyer
        </Button>
      </Dialog>
    </>
  );
};

export default SendNotificationDialog;
