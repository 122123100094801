import {
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Box,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styled from "@emotion/styled";
import { ImageInput, StyledLabel } from "./ImageInput";
import MaterialIconPickerDialog from "./MaterialIconPickerDialog";

const PreviewImage = styled("img")({
  maxWidth: "100%",
  maxHeight: "200px",
});

const EditCategoryDialog = ({
  openEditCategoryDialog,
  setOpenEditCategoryDialog,
  handleEditCategory,
  selectedCategory,
}) => {
  const [formValues, setFormValues] = useState({
    icon: selectedCategory.icon,
    name: selectedCategory.name,
    description: selectedCategory.description,
    main_illustration: selectedCategory.main_illustration,
  });
  const [formErrors, setFormErrors] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [showIconPickerDialog, setShowIconPickerDialog] = useState(false);

  const handleFormValuesChange = (field, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleImageChange = (event) => {
    const reader = new FileReader();
    const file = event.target.files[0];

    reader.onloadend = () => {
      setPreviewImage(reader.result);
      handleFormValuesChange("main_illustration", file);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleFormValidation = () => {
    const errors = {};

    if (!formValues.icon) {
      errors.icon = "Vous devez sélectionner une icône";
    }

    if (!formValues.name) {
      errors.name = "Le nom de la catégorie est requis";
    }

    if (!formValues.description) {
      errors.description = "La description de la catégorie est requise";
    }

    if (!formValues.main_illustration) {
      errors.main_illustration = "L'illustration principale est requise";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleEditButtonClick = () => {
    if (handleFormValidation()) {
      handleEditCategory(formValues);
    }
  };

  const handleSelectIconClicked = () => {
    setShowIconPickerDialog(true);
  };

  const onIconSelected = (icon) => {
    handleFormValuesChange("icon", icon);
    setShowIconPickerDialog(false);
  };

  return (
    <>
      <Dialog
        open={openEditCategoryDialog}
        onClose={() => setOpenEditCategoryDialog(false)}
      >
        <DialogTitle>Modifier la catégorie</DialogTitle>
        <DialogContent>
          <TextField
            label="Nom"
            fullWidth
            margin="normal"
            value={formValues.name}
            onChange={(event) =>
              handleFormValuesChange("name", event.target.value)
            }
            // @ts-ignore
            error={formErrors.name !== undefined}
            // @ts-ignore
            helperText={formErrors.name}
          />
          <TextField
            label="Description"
            fullWidth
            margin="normal"
            value={formValues.description}
            onChange={(event) =>
              handleFormValuesChange("description", event.target.value)
            }
            // @ts-ignore
            error={formErrors.description !== undefined}
            // @ts-ignore
            helperText={formErrors.description}
          />
          <FormControl fullWidth sx={{ marginBottom: "20px" }}>
            <ImageInput
              onChange={handleImageChange}
              content={"Choisir une image"}
            />
            {previewImage && <PreviewImage src={previewImage} />}
            {!previewImage && formValues.main_illustration && (
              <PreviewImage src={formValues.main_illustration} />
            )}
            {// @ts-ignore
            formErrors.main_illustration && (
              <div style={{ color: "red" }}>
                {
                  // @ts-ignore
                  formErrors.main_illustration
                }
              </div>
            )}
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <Stack direction="row" spacing={4} alignItems="center">
              <StyledLabel
                onClick={handleSelectIconClicked}
                style={{ width: "200px" }}
              >
                Choisir une icône
              </StyledLabel>
              {formValues.icon && (
                <span className="material-icons" style={{ fontSize: "30px" }}>
                  {formValues.icon}
                </span>
              )}
            </Stack>
          </FormControl>

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditButtonClick}
            >
              Modifier
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenEditCategoryDialog(false)}
            >
              Annuler
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      <MaterialIconPickerDialog
        open={showIconPickerDialog}
        onClose={() => setShowIconPickerDialog(false)}
        onIconPicked={onIconSelected}
      />
    </>
  );
};

export default EditCategoryDialog;
