// @ts-ignore
import ApiRoutes from "domain/data/ApiRoutes";
import axios from "domain/lib/axios";
import { useNavigate } from "react-router-dom";
import authHeader from "../utils/authHeader";
import { useLocalStorage } from "./useLocalStorage";

// @ts-ignore
export const useFaq = ({ errorsHandler, globalErrorsHandler } = {}) => {
  
  const [apiKey, setApiKey] = useLocalStorage("apiKey");

  // @ts-ignore
  const getAll = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.get(ApiRoutes.faq.getAll());
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const getAllQuestions = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.get(ApiRoutes.faq.getAllQuestions());
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const getById = async ({ onErrors, onSuccess, faqId } = {}) => {
    try {
      let res = await axios.get(ApiRoutes.faq.getById(faqId));
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const deleteById = async ({ onErrors, onSuccess, faqId } = {}) => {
    try {
      let res = await axios.delete(ApiRoutes.faq.deleteById(faqId), {
        headers: authHeader(apiKey),
      });
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

   // @ts-ignore
  const update = async ({ onErrors, onSuccess, faqId, data } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.faq.update(faqId), data, {
        headers: authHeader(apiKey),
      });
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };


   // @ts-ignore
   const register = async ({ onErrors, onSuccess, data } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.faq.register(), data, {
        headers: authHeader(apiKey),
      });
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };


  return {
    update,
    getAll,
    getAllQuestions,
    getById,
    deleteById,
    register,
  }
};
