import * as React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Signup from "./Signup";

export default function AdminSpaceAddUserDialog(props) {
  const { onClose, onCreate, open, formObject } = props;


  return (
    <Dialog onClose={onClose} open={open}>
      <Signup
        // @ts-ignore
        onCreate={onCreate}
        formObject={formObject}
      />
    </Dialog>
  );
}



