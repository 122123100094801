import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  IconButton,
  Tooltip,
  FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styled from "@emotion/styled";
import { ImageInput, SelectedPhotos } from "./ImageInput";

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 200px;
`;

const EditProductDialog = ({
  openEditProductDialog,
  setOpenEditProductDialog,
  handleEditProduct,
  selectedProduct,
  productCategories,
  productCategory = null,
}) => {
  const [formValues, setFormValues] = useState({
    name: selectedProduct.name,
    description: selectedProduct.description,
    main_illustration: selectedProduct.main_illustration
      ? selectedProduct.main_illustration.download_url
      : null,
    product_category_id: selectedProduct.categories[0].id,
    product_sub_category_id: selectedProduct.product_sub_category_id,
    tags: selectedProduct.tags.map((it) => it.name),
    attributes_values: selectedProduct.attributes.map((it) => ({
      value: it.value,
      product_attribute_id: it.product_attribute_id,
    })),
    optional_illustrations: [],
  });
  const [formErrors, setFormErrors] = useState({});
  const [previewImage, setPreviewImage] = useState(null);

  React.useEffect(() => {
    setFormValues({
      name: selectedProduct.name,
      description: selectedProduct.description,
      main_illustration: selectedProduct.main_illustration
        ? selectedProduct.main_illustration.download_url
        : null,
      product_category_id: selectedProduct.categories[0].id,
      product_sub_category_id: selectedProduct.product_sub_category_id,
      tags: selectedProduct.tags.map((it) => it.name),
      attributes_values: selectedProduct.attributes.map((it) => ({
        value: it.value,
        product_attribute_id: it.product_attribute_id,
      })),
      optional_illustrations: [],
    });
  }, [selectedProduct]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormValues((prevValues) => ({
        ...prevValues,
        main_illustration: file,
      }));
      setPreviewImage(URL.createObjectURL(file));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        main_illustration: null,
      }));
      setPreviewImage(null);
    }
  };

  const handleKeywordsChange = (event) => {
    if (event.key === "Enter") {
      const keyword = event.target.value.trim();
      if (keyword) {
        setFormValues((prevValues) => ({
          ...prevValues,
          tags: [...prevValues.tags, keyword],
        }));
        event.target.value = "";
      }
      event.preventDefault();
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formValues.product_category_id) {
      errors.product_category_id = "Vous devez sélectionner une catégorie";
    } else if (isNaN(parseFloat(formValues.product_category_id))) {
      errors.product_category_id = "La valeur doit être un entier";
    }
    if (!formValues.name) {
      errors.name = "Le nom est requis";
    }
    if (!formValues.description) {
      errors.description = "La description est requise";
    }

    if (!formValues.main_illustration) {
      errors.main_illustration = "L'illustration principale est requise";
    } else if (formValues.main_illustration.size > 1024 * 1024 * 2) {
      errors.main_illustration =
        "La taille du fichier doit être en dessous de 2 MB";
    } else if (
      !["image/jpeg", "image/png", "image/gif"].includes(
        formValues.main_illustration.type
      )
    ) {
      errors.main_illustration =
        "Seul les formats JPEG, PNG and GIF sont supportés";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAttributeChange = (event, attributeId) => {
    const { value } = event.target;

    const update = (oldValues, value, attributeId) => {
      let found = oldValues.find(
        (it) => it.product_attribute_id == attributeId
      );
      if (found) found.value = value;
      else {
        oldValues.push({
          value: value,
          product_attribute_id: attributeId,
        });
      }

      return oldValues;
    };

    let updated = update(
      formValues.attributes_values.slice(),
      value,
      attributeId
    );

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      attributes_values: updated,
    }));
  };

  const handleCategoryChange = (event) => {
    formValues.attributes_values = [];
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      product_category_id: event.target.value,
      attributes_values: [],
    }));
  };

  const handleSubCategoryChange = (event) => {
    formValues.attributes_values = [];
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      product_sub_category_id: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // Here you would typically make a request to your API to save the new product data
      console.log("Form values:", formValues);
      handleEditProduct(formValues);
    }
  };

  const handleOptionalIllustrationsChange = (event) => {
    const files = [...event.target.files];
    if (files && files.length > 0) {
      setFormValues((prevValues) => ({
        ...prevValues,
        optional_illustrations: files,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        optional_illustrations: [],
      }));
    }
  };

  const handleRemoveOptionalImagesFileAt = (index) => {
    console.log("handleRemoveOptionalImagesFileAt");
    console.log(formValues.optional_illustrations);
    let images = formValues.optional_illustrations.slice();
    images.splice(index, 1);
    console.log("images", images);
    console.log("optional_illustrations", formValues.optional_illustrations);
    setFormValues((prevValues) => ({
      ...prevValues,
      optional_illustrations: images,
    }));
  };

  return (
    <Dialog
      open={openEditProductDialog}
      onClose={() => setOpenEditProductDialog(false)}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add a new product</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>
          {productCategories && (
            <FormControl fullWidth>
              <InputLabel id="category-select-label">Catégorie</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                name="product_category_id"
                label = "Catégorie"
                value={formValues.product_category_id}
                onChange={handleCategoryChange}
              >
                {productCategories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {(formValues.product_category_id || productCategory) &&
            (() => {
              const _selectedCategory = productCategories
                ? productCategories.find(
                    (category) => category.id === formValues.product_category_id
                  )
                : productCategory;

              console.log("L'attribut tu produit est }} ", productCategory);

              return (
                <FormControl fullWidth>
                  <InputLabel id="sub-category-select-label">
                    Sous catégories
                  </InputLabel>
                  <Select
                    labelId="sub-category-select-label"
                    id="sub-category-select"
                    name="product_sub_category_id"
                    margin="dense"
                    label = "Sous catégories"
                    value={formValues.product_sub_category_id}
                    onChange={handleSubCategoryChange}
                  >
                    {_selectedCategory.sub_categories.map((subCategory) => (
                      <MenuItem key={subCategory.id} value={subCategory.id}>
                        {subCategory.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            })()}
          <TextField
            name="name"
            label="Name"
            fullWidth
            margin="normal"
            value={formValues.name}
            onChange={handleInputChange}
            // @ts-ignore
            error={!!formErrors.name}
            // @ts-ignore
            helperText={formErrors.name}
          />
          <TextField
            name="description"
            label="Description"
            fullWidth
            margin="normal"
            value={formValues.description}
            onChange={handleInputChange}
            // @ts-ignore
            error={!!formErrors.description}
            // @ts-ignore
            helperText={formErrors.description}
          />
          <TextField
            name="tags"
            label="Tags"
            fullWidth
            margin="normal"
            onKeyDown={handleKeywordsChange}
          />
          <div style={{ marginTop: "1rem" }}>
            {formValues.tags.map((keyword) => (
              <Chip
                key={keyword}
                label={keyword}
                onDelete={() => handleKeywordsChange(keyword)}
                style={{ marginRight: "0.5rem", marginBottom: "0.5rem" }}
              />
            ))}
          </div>
          {(formValues.product_category_id || productCategory) &&
            (() => {
              const _selectedCategory = productCategories
                ? productCategories.find(
                    (category) => category.id === formValues.product_category_id
                  )
                : productCategory;

              return _selectedCategory.product_attributes.length > 0 ? (
                <p style={{ marginTop: "1rem" }}>Les attributs du produit</p>
              ) : (
                ""
              );
            })()}

          {(formValues.product_category_id || productCategory) &&
            (() => {
              const _selectedCategory = productCategories
                ? productCategories.find(
                    (category) => category.id === formValues.product_category_id
                  )
                : productCategory;

              return _selectedCategory.product_attributes.map((attribute) => (
                <div key={attribute.id}>
                  <TextField
                    name={`attribute_${attribute.id}`}
                    label={attribute.name}
                    fullWidth
                    margin="normal"
                    value={attribute.description}
                    InputProps={{ readOnly: true }}
                  />
                  {/*<TextField
                    name={`attribute_${attribute.id}_type`}
                    label={attribute.type}
                    fullWidth
                    margin="normal"
                    value={attribute.type}
                    InputProps={{ readOnly: true }}
                  />*/}
                  <TextField
                    name={`attribute_${attribute.name}_value`}
                    label="Value"
                    fullWidth
                    margin="normal"
                    value={(() => {
                      let found = formValues.attributes_values.find(
                        (it) => it.product_attribute_id === attribute.id
                      );
                      if (found) return found.value;
                      else return "";
                    })()}
                    onChange={(event) =>
                      handleAttributeChange(event, attribute.id)
                    }
                  />
                </div>
              ));
            })()}
          <FormControl fullWidth>
            <ImageInput
              style={{ margin: "1rem 0" }}
              multiple={false}
              onChange={handleImageChange}
              content={"Image principale"}
            />
            {previewImage && <PreviewImage src={previewImage} />}
            {!previewImage && formValues.main_illustration && (
              <PreviewImage src={formValues.main_illustration} />
            )}
            {
              // @ts-ignore
              formErrors.main_illustration && (
                <div style={{ color: "red" }}>
                  {
                    // @ts-ignore
                    formErrors.main_illustration
                  }
                </div>
              )
            }
          </FormControl>

          <FormControl fullWidth>
            <ImageInput
              style={{ marginTop: "1rem" }}
              multiple={true}
              onChange={handleOptionalIllustrationsChange}
              content={"Choisir d'autres images"}
            />
            {formValues.optional_illustrations &&
              formValues.optional_illustrations.length > 0 && (
                <SelectedPhotos
                  style={{ marginTop: "20px" }}
                  photos={formValues.optional_illustrations}
                  onRemoveFileAt={handleRemoveOptionalImagesFileAt}
                />
              )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditProductDialog(false)}>
            Annuler
          </Button>
          <Button color="primary" type="submit">
            Modifier
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditProductDialog;
