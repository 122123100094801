const ApiRoutes = {
    auth: {
        register: () => "/api/admin/auth/register",
        login: () => "/api/admin/auth/login",
        logout: () => "/api/auth/logout",
    },
    adminSpace: {
        authenticated: () => "api/admin/space/authenticated",
        allUsers: () => "api/admin/space/all",
        delete: (userId) => `api/admin/space/delete/${userId}`,
        update: (userId) => `api/admin/space/update/${userId}`,
    },
    product: {
        getAll: ()=>"api/product/all",
        getById: (productId)=>`api/product/${productId}`,
        genericFind: ()=>"api/product/generic-find",
        tagFind: ()=>"api/product/tag-find",
        productFind: ()=>"api/product/product-find",
        priceFindEqual: ()=>"api/product/price-find/equal",
        priceFindGreater: ()=>"api/product/price-find/greater",
        priceFindLower: ()=>"api/product/price-find/lower",
        mostLikesPodium: ()=>"api/product/most-likes/podium",
        mostDislikesPodium: ()=>"api/product/most-dislikes/podium",
        mostCommentsPodium: ()=>"api/product/most-comments/podium",
        mostLikesAll: ()=>"api/product/most-likes/all",
        mostDislikesAll: ()=>"api/product/most-dislikes/all",
        mostCommentsAll: ()=>"api/product/most-comments/all",
        register: ()=>"api/admin/product/register",
        update: (productId)=>`api/admin/product/update/${productId}`,
        deleteProduct: (productId)=>`api/admin/product/delete/${productId}`,
        getAllUsersProducts: ()=>`api/admin/product/users/all`,
        approveProduct: (productId)=>`api/admin/product/approve/${productId}`,
        disapproveProduct: (productId)=>`api/admin/product/disapprove/${productId}`,
    },
    productCategory: {
        getById: (productCategoryId)=>`api/product-category/${productCategoryId}`,
        allWithProductAttributes: () => "api/product-category/all/with/product-attributes",
        allShort: () => "api/product-category/all/short",
        all: () => "api/product-category/all",
        register: () => "api/admin/product-category/register",
        addProductAttribute: () => "api/admin/product-category/add/product-attribute",
        removeProductAttributes: () => "api/admin/product-category/remove/product-attribute",
        deleteProductCategory: (productCategoryId) => `api/admin/product-category/delete/${productCategoryId}`,
        updateProductCategory: (productCategoryId) => `api/admin/product-category/update/${productCategoryId}`,
    },
    productAttribute: {
        getAll: ()=> "api/product-attribute/all",
        allShort: ()=> "api/product-attribute/all/short",
        allWithProductCategories: ()=> "api/all/with/product-categories",
        all: (categoryId) => `api/admin/product-attribute/all/product-category/${categoryId}`,
        register: () => `api/admin/product-attribute/all/register`,
        updateProductAttribute: (productAttributeId) => `api/admin/product-attribute/update/${productAttributeId}`,
        deleteProductAttribute: (productAttributeId) => `api/admin/product-attribute/delete/${productAttributeId}`,
    },

    productSubCategory: {
        register: ()=> "api/admin/product-sub-category/register",
        update: (subCategoryId)=> `api/admin/product-sub-category/update/${subCategoryId}`,
        deleteById: (subCategoryId)=> `api/admin/product-sub-category/delete/${subCategoryId}`,
    },
    faq: {
        register: ()=> "api/admin/faq/register",
        update: (faqId)=> `api/admin/faq/update/${faqId}`,
        deleteById: (faqId)=> `api/admin/faq/delete/${faqId}`,
        getById: (faqId)=> `api/faq/${faqId}`,
        getAll: ()=> `api/faq/all/`,
        getAllQuestions: ()=> `api/faq/questions/all`,
    },
    user: {
        notifyAll: ()=> "api/admin/user/notify/all",
        banishById: (userId)=> `api/admin/user/banish/${userId}`,
        notifyById: (userId)=> `api/admin/user/notify/${userId}`,
        getById: (userId)=> `api/admin/user/${userId}`,
        getAll: ()=> `api/admin/user/all`,
    },
}

export default ApiRoutes