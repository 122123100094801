import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Paper,
  TableContainer,
  Typography,
  Fab,
} from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AttributeDialog from "ui/components/AttributeDialog";
import ConfirmDialog from "ui/components/ConfirmDialog";
import AddProductAttributeDialog from "ui/components/AddProductAttributeDialog";
import { useApi } from "domain/hooks/useApi";
import Swal from "sweetalert2";
import { showLoadingAlert } from "ui/utils/utils";
import EditProductAttributeDialog from "ui/components/EditProductAttributDialog";

export default function AttributePage() {
  const [productAttributes, setAttributes] = useState([]);
  const [newProductAttributes, setNewAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [editProductAttribute, setEditProductAttribute] = useState(null);
  const [openAddAttributeDialog, setOpenAddAttributeDialog] = useState(false);
  const [openEditAttributeDialog, setOpenEditAttributeDialog] = useState(false);
  const [
    openConfirmAddProductAttributeDialog,
    setOpenConfirmAddProductAttributeDialog,
  ] = useState(false);
  const [
    openConfirmDeleteProductAttributeDialog,
    setOpenConfirmDeleteProductAttributeDialog,
  ] = useState(false);
  const [
    openConfirmEditProductAttributeDialog,
    setOpenConfirmEditProductAttributeDialog,
  ] = useState(false);

  const { ProductAttributeRequests } = useApi();
  React.useEffect(() => {
    loadProductAttributes();
  }, []);

  const loadProductAttributes = async () => {
    Swal.showLoading();
    try {
      let data = await ProductAttributeRequests.getAll();
      console.log("Attributes");
      console.log(data);
      setAttributes(data);
      Swal.close();
    } catch (e) {
      console.log(e);
      Swal.fire({
        text: "Une erreur est survenue en chargeant les données",
        icon: "error",
      });
    }
  };

  const handleEditAttribute = (attribute) => {
    setSelectedAttribute(attribute);
    setOpenEditAttributeDialog(true)
  };

  const handleDeleteAttribute = (attribute) => {
    setSelectedAttribute(attribute);
    // Show delete confirm attribute dialog
    setOpenConfirmDeleteProductAttributeDialog(true);
  };

  const handleConfirmDeleteProductAttributeClick = async () => {
    // remove the selected attributes.
    setOpenConfirmDeleteProductAttributeDialog(false);
    try {
      Swal.showLoading();
      // @ts-ignore
      await ProductAttributeRequests.deleteProductAttribute({
        productAttributeId: selectedAttribute.id,
      });
      let filtered = productAttributes.filter(
        (it) => it.id != selectedAttribute.id
      );
      setAttributes(filtered);
      setSelectedAttribute(null);
      // await loadProductCategories()
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant de supprimer la catégorie.",
      });
    }
  };

  const handleConfirmEditProductAttribute = async () => {
    setOpenConfirmEditProductAttributeDialog(false);

    console.log("updating");

    // @ts-ignore
    showLoadingAlert();

    try {
      // @ts-ignore
      await ProductAttributeRequests.updateProductAttribute({
        productAttributeId: selectedAttribute.id,
        data: editProductAttribute,
      });

      Swal.fire({
        text: "Votre modification a été enregistrée avec succès",
        icon: "success",
      }).then(async () => {
        showLoadingAlert();
        try {
          await loadProductAttributes();
          setEditProductAttribute(null);
          setSelectedAttribute(null);
        } catch (e) {
          Swal.close();
          Swal.fire({
            icon: "error",
            text: "Une erreur est survenue en essayant de recharger la page",
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant d'enregistrer",
      });
    }
  };

  const handleAddProductAttribute = (newAttributes) => {
    setNewAttributes(newAttributes);
    setOpenConfirmAddProductAttributeDialog(true);
  };

  const handleEditProductAttribute = (formValues) => {
    setEditProductAttribute(formValues);
    setOpenConfirmEditProductAttributeDialog(true);
  };

  const handleConfirmAddProductAttributeClick = async () => {
    // Make api call.
    setOpenConfirmAddProductAttributeDialog(false);
    showLoadingAlert();
    console.log("Product attributes");
    console.log(newProductAttributes);
    try {
      // @ts-ignore
      await ProductAttributeRequests.register({
        data: newProductAttributes,
      });
      Swal.fire({
        text: "Votre ajout a été enregistrée avec succès",
        icon: "success",
      }).then(async () => {
        showLoadingAlert();
        try {
          await loadProductAttributes();
        } catch (e) {
          Swal.close();
          Swal.fire({
            icon: "error",
            text: "Une erreur est survenue en essayant de recharger la page",
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant d'enregistrer",
      });
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Typography variant="h5" component="h2" align="center">
          Liste des attributs
        </Typography>
        {productAttributes.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Produits</TableCell>
                <TableCell>Categories</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productAttributes.map((attribute) => (
                <TableRow
                  key={attribute.id}
                  selected={selectedAttribute?.id == attribute.id}
                >
                  <TableCell>{attribute.name}</TableCell>
                  <TableCell>{attribute.description}</TableCell>
                  <TableCell>{attribute.type}</TableCell>
                  <TableCell>{attribute.products.length}</TableCell>
                  <TableCell>{attribute.product_categories.length}</TableCell>
                  <TableCell>
                    <Tooltip title="Editer l'attribut'">
                      <IconButton
                        onClick={() => handleEditAttribute(attribute)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer l'attribut'">
                      <IconButton
                        onClick={() => handleDeleteAttribute(attribute)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography ml={2} variant="body1">
            Aucun attribut n'est encore créé
          </Typography>
        )}
      </TableContainer>

      <Fab
        color="primary"
        aria-label="add"
        onClick={() => setOpenAddAttributeDialog(true)}
        sx={{ position: "fixed", bottom: "10px", right: "10px" }}
      >
        <AddIcon />
      </Fab>

      {/* AddProductAttributeDialog */}
      <AddProductAttributeDialog
        openAddProductAttributeDialog={openAddAttributeDialog}
        setOpenAddProductAttributeDialog={setOpenAddAttributeDialog}
        productAttributes={productAttributes}
        handleAddProductAttribute={handleAddProductAttribute}
      />

      {/* AddProductAttributeDialog */}
      {selectedAttribute && (
        <EditProductAttributeDialog
          openEditProductAttributeDialog={openEditAttributeDialog}
          setOpenEditProductAttributeDialog={setOpenEditAttributeDialog}
          productAttributes={productAttributes}
          productAttribute={selectedAttribute}
          handleEditProductAttribute={handleEditProductAttribute}
        />
      )}

      {/* Confirm AddProductAttribute dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmAddProductAttributeDialog}
        setOpenConfirmDialog={setOpenConfirmAddProductAttributeDialog}
        handleCancelClick={() => {
          setOpenConfirmAddProductAttributeDialog(false);
        }}
        handleConfirmClick={handleConfirmAddProductAttributeClick}
        title={"Êtes-vous sur ?"}
        content={"Voulez-vous ajouter cet attribut ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      {/* Confirm deleteProductAttribute dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmDeleteProductAttributeDialog}
        setOpenConfirmDialog={setOpenConfirmDeleteProductAttributeDialog}
        handleCancelClick={() => {
          setOpenConfirmDeleteProductAttributeDialog(false);
        }}
        handleConfirmClick={handleConfirmDeleteProductAttributeClick}
        title={"Êtes-vous sur ?"}
        content={"Voulez-vous supprimer cette catégorie ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      {/* Confirm editProductAttribute dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmEditProductAttributeDialog}
        setOpenConfirmDialog={setOpenConfirmEditProductAttributeDialog}
        handleCancelClick={() => {
          setOpenConfirmEditProductAttributeDialog(false);
        }}
        handleConfirmClick={handleConfirmEditProductAttribute}
        title={"Enregistrer les changements"}
        content={"Voulez-vous enregistrer vos changements ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />
    </>
  );
}
