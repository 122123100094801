import { ArrowBackIos } from "@mui/icons-material";
import { Box, Button, Fab, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import Swal from "sweetalert2";
import AddProductDialog from "ui/components/AddProductDialog";
import ConfirmDialog from "ui/components/ConfirmDialog";
import EditProductDialog from "ui/components/EditProductDialog";
import ProductsComponent from "ui/components/ProductsComponent";
import { showLoadingAlert } from "ui/utils/utils";
import AddIcon from "@mui/icons-material/Add";
import { useLocalStorage } from "domain/hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { useApi } from "domain/hooks/useApi";

const ProductPage = () => {
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [editProduct, setEditProduct] = React.useState(null);
  const [openAddProductDialog, setOpenAddProductDialog] = React.useState(false);
  const [openConfirmAddProductDialog, setOpenConfirmAddProductDialog] =
    React.useState(false);
  const [newProduct, setNewProduct] = React.useState(null);
  const [openConfirmDeleteProductDialog, setOpenConfirmDeleteProductDialog] =
    React.useState(false);
  const [openEditProductDialog, setOpenEditProductDialog] =
    React.useState(false);
  const [openConfirmEditProductDialog, setOpenConfirmEditProductDialog] =
    React.useState(false);
  const [productCategory, setProductCategory] = useLocalStorage(
    "productCategory",
    null
  );

  const navigate = useNavigate();
  const { ProductCategoryRequests, ProductRequests } = useApi();

  React.useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = async () => {
    // @ts-ignore
    let _productCategory = await ProductCategoryRequests.getById({productCategoryId: productCategory.id})
    console.log("The productCategory is ", _productCategory)
    setProductCategory(_productCategory)
    setProducts(productCategory.products);
  };

  const handleAddProduct = (newProduct) => {
    console.clear()
    console.log("Adding new product ", newProduct)
    setNewProduct(newProduct);
    setOpenConfirmAddProductDialog(true);
  };

  const handleConfirmAddProduct = async () => {
    let formData = new FormData();
    console.clear()
    console.log("The product to add is ", newProduct)
    formData.append("product_category_id", newProduct.product_category_id);
    formData.append("name", newProduct.name);
    formData.append("description", newProduct.description);
    if (newProduct.main_illustration) {
      formData.append("main_illustration", newProduct.main_illustration);
    }

    newProduct.optional_illustrations.forEach((file, index) => {
      formData.append(`optional_illustrations[${index}]`, file);
    });

    newProduct.tags.forEach((tag, index)=>{
      formData.append(`tags[${index}]`, tag)
    })

    newProduct.attributes_values.forEach((attribute, index)=>{
      formData.append(`attributes_values[${index}]`, JSON.stringify(attribute))
    })

    setOpenConfirmAddProductDialog(false);
    showLoadingAlert();

    try {
      // @ts-ignore
      await ProductRequests.register({
        data: formData,
      });
      Swal.fire({
        text: "Votre ajout a été enregistrée avec succès",
        icon: "success",
      }).then(async () => {
        showLoadingAlert();
        try {
          await loadProducts();
        } catch (e) {
          Swal.close();
          Swal.fire({
            icon: "error",
            text: "Une erreur est survenue en essayant de recharger la page",
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant d'enregistrer",
      });
    }
  };

  const handleConfirmEditProduct = async () => {
    setOpenConfirmEditProductDialog(false);
    console.clear()
    console.log("The product in edition is ", editProduct)
    let formData = new FormData();

    formData.append("name", editProduct.name);
    formData.append("description", editProduct.description);
    if (editProduct.main_illustration) {
      formData.append("main_illustration", editProduct.main_illustration);
    }

    editProduct.optional_illustrations.forEach((file, index) => {
      formData.append(`optional_illustrations[${index}]`, file);
    });

    editProduct.tags.forEach((tag, index)=>{
      formData.append(`tags[${index}]`, tag)
    })

    editProduct.attributes_values.forEach((attribute, index)=>{
      formData.append(`attributes_values[${index}]`, JSON.stringify(attribute))
    })

    showLoadingAlert();
    try {
      // @ts-ignore
      await ProductRequests.update({ productId: selectedProduct.id, data: formData });
      setSelectedProduct(null);
      
      Swal.fire({
        text: "Le produit a été mis à jour avec succès",
        icon: "success",
      }).then(async () => {
        showLoadingAlert();
        try {
          await loadProducts();
        } catch (e) {
          Swal.close();
          Swal.fire({
            icon: "error",
            text: "Une erreur est survenue en essayant de recharger la page",
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant d'enregistrer",
      });
    }
  };

  const handleEditProduct = (formValues) => {
    setEditProduct(formValues);
    setOpenConfirmEditProductDialog(true);
  };

  const handleEditProductClick = (product) => {
    setSelectedProduct(product);
    console.log(
      "The selected product is $$ ",
      selectedProduct,
      "and the original is ",
      product
    );
    setOpenEditProductDialog(true);
  };

  const handleConfirmDeleteProductClick = async () => {
    showLoadingAlert();
    try {
      // @ts-ignore
      await ProductRequests.deleteProduct({ productId: selectedProduct.id });
      let filtered = products.filter((it) => it.id != selectedProduct.id);
      setProducts(filtered);
      setSelectedProduct(null);
      // await loadProductCategories()
      Swal.fire({
        text: "L'article a été supprimé avec succès",
        icon: "success",
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant de supprimer l'article'",
      });
    }
    setOpenConfirmDeleteProductDialog(false);
  };

  const handleDelete = (product) => {
    setSelectedProduct(product);
    setOpenConfirmDeleteProductDialog(true);
  };

  return (
    <>
      <Box style={{ position: "relative", height: "100%" }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIos />
          </IconButton>
          <Typography
            sx={{ flexGrow: 1, textAlign: "center" }}
            variant="h5"
            component="h2"
            align="center"
          >
            Liste des produits
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (!productCategory) {
                Swal.fire({
                  icon: "error",
                  title: "Impossible",
                  text: "Aucune catégorie n'est encore créée",
                });
              } else {
                setOpenAddProductDialog(true);
              }
            }}
          >
            Ajouter un produit
          </Button>
        </Stack>

        <ProductsComponent
          products={products}
          handleDeleteProductClick={handleDelete}
          handleEditProductClick={handleEditProductClick}
        />

      </Box>
      {/* Add product dialog */}

      <AddProductDialog
        openAddProductDialog={openAddProductDialog}
        setOpenAddProductDialog={setOpenAddProductDialog}
        handleAddProduct={handleAddProduct}
        handleCancelAddProduct={() => setOpenAddProductDialog(false)}
        productCategories={null}
        productCategory={productCategory}
      />

      {/* EditProduct */}
      {selectedProduct && (
        <EditProductDialog
          openEditProductDialog={openEditProductDialog}
          setOpenEditProductDialog={setOpenEditProductDialog}
          handleEditProduct={handleEditProduct}
          selectedProduct={selectedProduct}
          productCategories={null}
          productCategory={productCategory}
        />
      )}

      {/* Confirm deleteProduct dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmDeleteProductDialog}
        setOpenConfirmDialog={setOpenConfirmDeleteProductDialog}
        handleCancelClick={() => {
          setOpenConfirmDeleteProductDialog(false);
        }}
        handleConfirmClick={handleConfirmDeleteProductClick}
        title={"Êtes-vous sur ?"}
        content={"Voulez-vous supprimer cette catégorie ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      {/* Confirm addProduct dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmAddProductDialog}
        setOpenConfirmDialog={setOpenConfirmAddProductDialog}
        handleCancelClick={() => {
          setOpenConfirmAddProductDialog(false);
        }}
        handleConfirmClick={handleConfirmAddProduct}
        title={"Créer un nouveau produit"}
        content={"Voulez-vous enregistrer ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      {/* Confirm editProduct dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmEditProductDialog}
        setOpenConfirmDialog={setOpenConfirmEditProductDialog}
        handleCancelClick={() => {
          setOpenConfirmDeleteProductDialog(false);
        }}
        handleConfirmClick={handleConfirmEditProduct}
        title={"Enregistrer les changements"}
        content={"Voulez-vous enregistrer vos changements ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />
    </>
  );
};

export default ProductPage;
