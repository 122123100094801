import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import InputError from "./InputError";
import Admin from "../../domain/data/Admin";

export default function Signup(props) {
  const { onCreate, formObject } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onCreateButtonClicked = () => {
    onCreate();
  };

  return (
    <Box padding={2}>
      <Stack spacing={2}>
        <Typography
          fontSize={28}
          fontWeight={500}
          component="h3"
          textAlign="center"
        >
          Créer un admin
        </Typography>
        <Typography
          variant="subtitle1"
          fontSize={14}
          textAlign="center"
          lineHeight={1.4}
          component="h4"
        >
          Pour se connecter à l'espace admin lecobaye.fr, l'admin devra saisir
          son e-mail et son mot de passe. **.
        </Typography>
        <FormControl required>
          <InputLabel htmlFor="input-firstname">Prénoms</InputLabel>
          <OutlinedInput
            id="input-firstname"
            label="Prénoms *"
            placeholder="Prénoms *"
            type="text"
            value={formObject.firstname}
            onChange={(e) => formObject.setFirstname(e.target.value)}
            sx={{ width: "100%" }}
          />
          <InputError messages={formObject.errors.firstname} />
        </FormControl>
        <FormControl required>
          <InputLabel htmlFor="input-lastname">Noms</InputLabel>
          <OutlinedInput
            id="input-lastname"
            label="Noms"
            placeholder="Noms *"
            type="text"
            value={formObject.lastname}
            onChange={(e) => formObject.setLastname(e.target.value)}
            sx={{ width: "100%" }}
          />
          <InputError messages={formObject.errors.lastname} />
        </FormControl>
        <FormControl required>
          <InputLabel htmlFor="input-email">Adresse e-mail</InputLabel>
          <OutlinedInput
            id="input-email"
            label="Adresse e-mail"
            placeholder="Adresse e-mail *"
            type="text"
            value={formObject.email}
            onChange={(e) => formObject.setEmail(e.target.value)}
            sx={{ width: "100%" }}
          />
          <InputError messages={formObject.errors.email} />
        </FormControl>

        <FormControl required>
          <InputLabel htmlFor="input-password">Mot de passe</InputLabel>
          <OutlinedInput
            id="input-password"
            label="Mot de passe"
            type={showPassword ? "text" : "password"}
            value={formObject.password}
            onChange={(e) => formObject.setPassword(e.target.value)}
            sx={{ width: "100%" }}
            endAdornment={
              <InputAdornment position="end">
                <Typography
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  Voir mot de passe
                </Typography>
              </InputAdornment>
            }
          />
          <InputError messages={formObject.errors.password} />
        </FormControl>

        <FormControl required>
          <InputLabel htmlFor="input-password-confirm">
            Confirmation Mot de passe
          </InputLabel>
          <OutlinedInput
            id="input-password-confirm"
            label="Confirmation Mot de passe"
            type={showPassword ? "text" : "password"}
            sx={{ width: "100%" }}
            value={formObject.password_confirmation}
            onChange={(e) => formObject.setPasswordConfirmation(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <Typography
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  Voir mot de passe
                </Typography>
              </InputAdornment>
            }
          />
          <InputError messages={formObject.errors.passwordConfirmation} />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Rôle</InputLabel>
          <Select
            labelId="select-label"
            id="role-select"
            value={formObject.userType}
            label="Age"
            // @ts-ignore
            onChange={(e)=>formObject.setUserType(e.target.value)}
          >
            <MenuItem value={Admin.Type.TYPE_ADMIN}>Admin</MenuItem>
            <MenuItem value={Admin.Type.TYPE_SUPER_ADMIN}>SuperAdmin</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Stack mt={2}>
        <Button
          type="submit"
          onClick={onCreateButtonClicked}
          variant="contained"
        >
          Créer le compte
        </Button>
      </Stack>
    </Box>
  );
}
