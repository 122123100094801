import Swal from "sweetalert2";

const showLoadingAlert = () => {
  // @ts-ignore

  Swal.fire({
    allowOutsideClick: false,
    showConfirmButton: false
  })
  
  Swal.showLoading()
};

export { showLoadingAlert };
