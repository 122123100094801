import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  TableContainer,
  Typography,
  Chip,
  Fab,
} from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "ui/components/ConfirmDialog";
import AddProductDialog from "ui/components/AddProductDialog";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { useApi } from "domain/hooks/useApi";
import { showLoadingAlert } from "ui/utils/utils";
import EditProductDialog from "ui/components/EditProductDialog";
import ProductsComponent from "ui/components/ProductsComponent";

export default function ProductPage() {
  const [products, setProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  const [openAddProductDialog, setOpenAddProductDialog] = useState(false);
  const [openConfirmAddProductDialog, setOpenConfirmAddProductDialog] =
    useState(false);
  const [newProduct, setNewProduct] = useState(null);
  const [openConfirmDeleteProductDialog, setOpenConfirmDeleteProductDialog] =
    useState(false);
  const [openEditProductDialog, setOpenEditProductDialog] = useState(false);
  const [openConfirmEditProductDialog, setOpenConfirmEditProductDialog] =
    useState(false);

  const { ProductCategoryRequests, ProductRequests } = useApi();

  React.useEffect(() => {
    loadProductCategories();
    loadProducts();
  }, []);

  const loadProducts = async () => {
    let data = await ProductRequests.getAll();
    console.log("Product loaded");
    console.log(data);
    setProducts(data);
  };

  const loadProductCategories = async () => {
    let data = await ProductCategoryRequests.all();
    console.log(data);
    setProductCategories(data);
  };

  const handleAddProduct = (newProduct) => {
    setNewProduct(newProduct);
    setOpenConfirmAddProductDialog(true);
  };

  const handleConfirmAddProduct = async () => {
    let formData = new FormData();
    console.clear()
    console.log("The product to add is ", newProduct)
    formData.append("product_category_id", newProduct.product_category_id);
    if (newProduct.product_sub_category_id) {
      formData.append("product_sub_category_id", newProduct.product_sub_category_id);
    }
    formData.append("name", newProduct.name);
    formData.append("description", newProduct.description);
    if (newProduct.main_illustration) {
      formData.append("main_illustration", newProduct.main_illustration);
    }

    newProduct.optional_illustrations.forEach((file, index) => {
      formData.append(`optional_illustrations[${index}]`, file);
    });

    newProduct.tags.forEach((tag, index)=>{
      formData.append(`tags[${index}]`, tag)
    })

    newProduct.attributes_values.forEach((attribute, index)=>{
      formData.append(`attributes_values[${index}]`, JSON.stringify(attribute))
    })

    setOpenConfirmAddProductDialog(false);
    showLoadingAlert();

    try {
      // @ts-ignore
      await ProductRequests.register({
        data: formData,
      });
      Swal.fire({
        text: "Votre ajout a été enregistrée avec succès",
        icon: "success",
      }).then(async () => {
        showLoadingAlert();
        try {
          await loadProducts();
          Swal.close()
        } catch (e) {
          Swal.fire({
            icon: "error",
            text: "Une erreur est survenue en essayant de recharger la page",
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant d'enregistrer",
      });
    }
  };

  const handleConfirmEditProduct = async () => {
    setOpenConfirmEditProductDialog(false);
    console.clear()
    console.log("The product in edition is ", editProduct)
    let formData = new FormData();

    formData.append("name", editProduct.name);
    formData.append("description", editProduct.description);
    if (editProduct.main_illustration) {
      formData.append("main_illustration", editProduct.main_illustration);
    }
    if (editProduct.product_sub_category_id) {
      formData.append("product_sub_category_id", newProduct.product_sub_category_id);
    }
    


    editProduct.optional_illustrations.forEach((file, index) => {
      formData.append(`optional_illustrations[${index}]`, file);
    });

    editProduct.tags.forEach((tag, index)=>{
      formData.append(`tags[${index}]`, tag)
    })

    editProduct.attributes_values.forEach((attribute, index)=>{
      formData.append(`attributes_values[${index}]`, JSON.stringify(attribute))
    })

    showLoadingAlert();
    try {
      // @ts-ignore
      await ProductRequests.update({ productId: selectedProduct.id, data: formData });
      setSelectedProduct(null);
      
      Swal.fire({
        text: "Le produit a été mis à jour avec succès",
        icon: "success",
      }).then(async () => {
        showLoadingAlert();
        try {
          await loadProducts();
          Swal.close()
        } catch (e) {
          Swal.fire({
            icon: "error",
            text: "Une erreur est survenue en essayant de recharger la page",
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant d'enregistrer",
      });
    }
  };

  const handleEditProduct = (formValues) => {
    setEditProduct(formValues);
    setOpenConfirmEditProductDialog(true);
  };

  const handleEditProductClick = (product) => {
    setSelectedProduct(product);
    setOpenEditProductDialog(true);
  };

  const handleConfirmDeleteProductClick = async () => {
    showLoadingAlert();
    try {
      // @ts-ignore
      await ProductRequests.deleteProduct({ productId: selectedProduct.id });
      let filtered = products.filter((it) => it.id != selectedProduct.id);
      setProducts(filtered);
      setSelectedProduct(null);
      // await loadProductCategories()
      Swal.fire({
        text: "L'article a été supprimé avec succès",
        icon: "success",
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant de supprimer l'article'",
      });
    }
    setOpenConfirmDeleteProductDialog(false);
  };

  const handleDelete = (product) => {
    setSelectedProduct(product);
    setOpenConfirmDeleteProductDialog(true);
  };
  return (
    <>
      <Typography variant="h5" component="h2" align="center">
        Liste des produits
      </Typography>

      <ProductsComponent
        products={products}
        handleDeleteProductClick={handleDelete}
        handleEditProductClick={handleEditProductClick}
      />

      <Fab
        color="primary"
        aria-label="add"
        onClick={() => {
          if (!productCategories.length)
            Swal.fire({
              icon: "error",
              title: "Impossible",
              text: "Aucune catégorie n'est encore créée",
            });
          else setOpenAddProductDialog(true);
        }}
        sx={{ position: "fixed", bottom: "10px", right: "10px" }}
      >
        <AddIcon />
      </Fab>

      {/* Add product dialog */}

      <AddProductDialog
        openAddProductDialog={openAddProductDialog}
        setOpenAddProductDialog={setOpenAddProductDialog}
        handleAddProduct={handleAddProduct}
        handleCancelAddProduct={() => setOpenAddProductDialog(false)}
        productCategories={productCategories}
      />

      {/* EditProduct */}
      {selectedProduct && (
        <EditProductDialog
          openEditProductDialog={openEditProductDialog}
          setOpenEditProductDialog={setOpenEditProductDialog}
          handleEditProduct={handleEditProduct}
          selectedProduct={selectedProduct}
          productCategories={productCategories}
        />
      )}

      {/* Confirm deleteProduct dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmDeleteProductDialog}
        setOpenConfirmDialog={setOpenConfirmDeleteProductDialog}
        handleCancelClick={() => {
          setOpenConfirmDeleteProductDialog(false);
        }}
        handleConfirmClick={handleConfirmDeleteProductClick}
        title={"Êtes-vous sur ?"}
        content={"Voulez-vous supprimer ce produit ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      {/* Confirm addProduct dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmAddProductDialog}
        setOpenConfirmDialog={setOpenConfirmAddProductDialog}
        handleCancelClick={() => {
          setOpenConfirmAddProductDialog(false);
        }}
        handleConfirmClick={handleConfirmAddProduct}
        title={"Créer un nouveau produit"}
        content={"Voulez-vous enregistrer ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      {/* Confirm editProduct dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmEditProductDialog}
        setOpenConfirmDialog={setOpenConfirmEditProductDialog}
        handleCancelClick={() => {
          setOpenConfirmDeleteProductDialog(false);
        }}
        handleConfirmClick={handleConfirmEditProduct}
        title={"Enregistrer les changements"}
        content={"Voulez-vous enregistrer vos changements ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />
    </>
  );
}
