import ApiRoutes from 'domain/data/ApiRoutes'
import RoutesName from 'domain/data/RoutesName'
import axios from 'domain/lib/axios'
import { useNavigate } from 'react-router-dom'
import authHeader from "../utils/authHeader";
import {useAuth} from "./useAuth";
import {useLocalStorage} from "./useLocalStorage";

// @ts-ignore
export const useProductAttribute = ({errorsHandler, globalErrorsHandler}={}) => {

    const navigate = useNavigate()
    const {apiKey} = useAuth()
    const [user, setUser] = useLocalStorage("user", null)

    const processError = (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error("An error happened on the server");
            console.error("The HTTP status code is ", error.response.status);
            console.error(error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("The request was made but no response was received");
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error("An unknown error occurred");
            console.error(error);
          }
        if (error.response.status === 401 || error.response.status === 419) {
            console.log("You are not authenticated")
            if (!globalErrorsHandler) {
                console.log("You are not authenticated")
                setUser(null)
                navigate(RoutesName.login)
            }
        }
        
    }

    if (!errorsHandler) errorsHandler = processError

    // @ts-ignore
    const getAll = async ({onErrors, onSuccess} = {}) => {

        try {
            let res = await axios.get(ApiRoutes.productAttribute.getAll())
            if (onSuccess) onSuccess(res.data)
            return res.data
        }catch(error){
            console.log("An eror occurred")
            console.log(error);
            if (onErrors) onErrors(error)
            if (errorsHandler) errorsHandler(error)
            if (globalErrorsHandler) globalErrorsHandler(error)
            if (!onErrors || !errorsHandler) throw error
        }
    }

    // @ts-ignore
    const all = async ({onErrors, onSuccess, productCategoryId} = {}) => {
        try {
            let res = await axios.get(ApiRoutes.productAttribute.all(productCategoryId), {headers: authHeader(apiKey)})
            if (onSuccess) onSuccess(res.data)
            return res.data
        }catch(error){
            console.log("An eror occurred")
            console.log(error);
            if (onErrors) onErrors(error)
            if (errorsHandler) errorsHandler(error)
            if (globalErrorsHandler) globalErrorsHandler(error)
            if (!onErrors || !errorsHandler) throw error
        }
    }

    // @ts-ignore
    const allShort = async ({onErrors, onSuccess} = {}) => {
        try {
            let res = await axios.get(ApiRoutes.productAttribute.allShort())
            if (onSuccess) onSuccess(res.data)
            return res.data
        }catch(error){
            console.log("An eror occurred")
            console.log(error);
            if (onErrors) onErrors(error)
            if (errorsHandler) errorsHandler(error)
            if (globalErrorsHandler) globalErrorsHandler(error)
            if (!onErrors || !errorsHandler) throw error
        }
    }

    // @ts-ignore
    const allWithProductCategories = async ({onErrors, onSuccess} = {}) => {
        try {
            let res = await axios.get(ApiRoutes.productAttribute.allWithProductCategories())
            if (onSuccess) onSuccess(res.data)
            return res.data
        }catch(error){
            console.log("An eror occurred")
            console.log(error);
            if (onErrors) onErrors(error)
            if (errorsHandler) errorsHandler(error)
            if (globalErrorsHandler) globalErrorsHandler(error)
            if (!onErrors || !errorsHandler) throw error
        }
    }

    // @ts-ignore
    const register = async ({onErrors, onSuccess, data} = {}) => {
        try {
            let res = await axios.post(ApiRoutes.productAttribute.register(), data, {headers: authHeader(apiKey)})
            if (onSuccess) onSuccess(res.data)
            return res.data
        }catch(error){
            console.log("An eror occurred")
            console.log(error);
            if (onErrors) onErrors(error)
            if (errorsHandler) errorsHandler(error)
            if (globalErrorsHandler) globalErrorsHandler(error)
            if (!onErrors || !errorsHandler) throw error
        }
    }

    // @ts-ignore
    const updateProductAttribute = async ({onErrors, onSuccess, productAttributeId, data} = {}) => {
        try {
            let res = await axios.post(ApiRoutes.productAttribute.updateProductAttribute(productAttributeId), data, {headers: authHeader(apiKey)})
            if (onSuccess) onSuccess(res.data)
            return res.data
        }catch(error){
            console.log("An eror occurred")
            console.log(error);
            if (onErrors) onErrors(error)
            if (errorsHandler) errorsHandler(error)
            if (globalErrorsHandler) globalErrorsHandler(error)
            if (!onErrors || !errorsHandler) throw error
        }
    }

    // @ts-ignore
    const deleteProductAttribute = async ({onErrors, onSuccess, productAttributeId} = {}) => {
        try {
            let res = await axios.delete(ApiRoutes.productAttribute.deleteProductAttribute(productAttributeId), {headers: authHeader(apiKey)})
            if (onSuccess) onSuccess(res.data)
            return res.data
        }catch(error){
            console.log("An eror occurred")
            console.log(error);
            if (onErrors) onErrors(error)
            if (errorsHandler) errorsHandler(error)
            if (globalErrorsHandler) globalErrorsHandler(error)
            if (!onErrors || !errorsHandler) throw error
        }
    }

    return {
        getAll,
        allShort,
        allWithProductCategories,
        all,
        register,
        updateProductAttribute,
        deleteProductAttribute
    }

}