import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Fab,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "ui/components/ConfirmDialog";
import { showLoadingAlert } from "ui/utils/utils";
import Swal from "sweetalert2";
import { useApi } from "domain/hooks/useApi";
export default function UserProductPage() {
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [
    openConfirmApprovedProductDialog,
    setOpenConfirmApprovedProductDialog,
  ] = React.useState(false);
  const [
    openConfirmDisapprovedProductDialog,
    setOpenConfirmDisapprovedProductDialog,
  ] = React.useState(false);
  const [openConfirmDeleteProductDialog, setOpenConfirmDeleteProductDialog] =
    React.useState(false);

  const { ProductRequests } = useApi();

  React.useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = async () => {
    let data = await ProductRequests.getAllUsersProducts();
    console.log("Product loaded");
    console.log(data);
    setProducts(data);
  };

  const handleToggleProductSelection = (checked, product) => {
    if (checked && !selectedProducts.includes(product)) {
      setSelectedProducts([...selectedProducts, product]);
    } else if (!checked) {
      setSelectedProducts(selectedProducts.filter((it) => it.id != product.id));
    }
  };

  const handleViewProductClick = (product) => {};

  const handleApprovedProductClick = (product) => {
    setOpenConfirmApprovedProductDialog(true);
  };

  const handleDisapprovedProductClick = (product) => {
    setOpenConfirmDisapprovedProductDialog(true);
  };

  const handleDeleteProductClick = (product) => {
    setOpenConfirmDeleteProductDialog(true);
  };

  const handleConfirmApprovedProductClick = () => {
    showLoadingAlert();
    // @ts-ignore
    ProductRequests.approveProduct({ productId: selectedProduct.id })
      .then(() => {
        const updatedProducts = products.map((product) =>
          product.id === selectedProduct.id
            ? { ...product, approved: true }
            : product
        );
        setProducts(updatedProducts);
        setOpenConfirmApprovedProductDialog(false);
        Swal.fire({
          text: "Le produit a été approuvé avec succès",
          icon: "success",
        });
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          text: "Une erreur est survenue en essayant d'approuver le produit",
        });
      });
  };

  const handleConfirmDisapprovedProductClick = () => {
    showLoadingAlert();
    // @ts-ignore
    ProductRequests.disapproveProduct({ productId: selectedProduct.id })
      .then(() => {
        const updatedProducts = products.map((product) =>
          product.id === selectedProduct.id
            ? { ...product, approved: false }
            : product
        );
        setProducts(updatedProducts);
        setOpenConfirmDisapprovedProductDialog(false);
        Swal.fire({
          text: "Le produit a été désapprouvé avec succès",
          icon: "success",
        });
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          text: "Une erreur est survenue en essayant de désapprouver le produit",
        });
      });
  };

  const handleConfirmDeleteProductClick = async () => {
    showLoadingAlert();
    try {
      // @ts-ignore
      await ProductRequests.deleteProduct({ productId: selectedProduct.id });
      let filtered = products.filter((it) => it.id != selectedProduct.id);
      setProducts(filtered);
      let filteredSelected = selectedProducts.filter(
        (it) => it.id != selectedProduct.id
      );
      setSelectedProducts(filteredSelected);
      setSelectedProduct(null);
      setOpenConfirmDeleteProductDialog(false);
      Swal.fire({
        text: "Le produit a été supprimé avec succès",
        icon: "success",
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "Une erreur est survenue en essayant de supprimer le produit",
      });
    }
    setOpenConfirmDeleteProductDialog(false);
  };

  return (
    <>
      <Box sx={{ userSelect: "none" }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography sx={{ flexGrow: 1 }} variant="h5" component="h2">
            Liste des produits utilisateurs
          </Typography>

          {selectedProducts.length > 0 && (
            <>
              <Button
                variant="outlined"
                color="warning"
                startIcon={<CloseIcon />}
                onClick={() => console.log("Not implemented")}
              >
                Désapprouver
              </Button>

              <Button
                variant="outlined"
                color="primary"
                startIcon={<CheckIcon />}
                onClick={() => console.log("Not implemented")}
              >
                Approuver
              </Button>

              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => console.log("Not implemented")}
              >
                Supprimer
              </Button>
            </>
          )}
        </Stack>
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          {products.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={selectedProducts.length === products.length}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedProducts(products)
                          : setSelectedProducts([])
                      }
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Mots clés</TableCell>
                  <TableCell sx={{}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow
                    key={product.id}
                    selected={
                      selectedProducts.includes(product) ||
                      (selectedProduct && selectedProduct.id == product.id)
                    }
                    onClick={() => {
                      setSelectedProduct(product);
                    }}
                    sx={
                      product.approved
                        ? { fontWeight: "normal" }
                        : { fontWeight: "bold" }
                    }
                  >
                    <TableCell>
                      <Checkbox
                        checked={selectedProducts.includes(product)}
                        onChange={(_, checked) =>
                          handleToggleProductSelection(checked, product)
                        }
                      />
                    </TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.description}</TableCell>
                    <TableCell><Chip color={product.approved ? "success": "error"} label={product.approved ? "Approuvé" : "Non approuvé"} /></TableCell>
                    <TableCell>
                      {product.tags &&
                        product.tags.map((tag) => (
                          <Chip key={tag.id} label={tag.name} />
                        ))}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row">
                        <Tooltip title="Voir le produit">
                          <IconButton
                            aria-label="view"
                            onClick={() => handleViewProductClick(product)}
                          >
                            <VisibilityIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Désapprouver le produit">
                          <IconButton
                            aria-label="disapproved"
                            onClick={() =>
                              handleDisapprovedProductClick(product)
                            }
                          >
                            <CloseIcon color="warning" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Approuver le produit">
                          <IconButton
                            aria-label="approved"
                            onClick={() => handleApprovedProductClick(product)}
                          >
                            <CheckIcon color="success" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Supprimer le prouit">
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDeleteProductClick(product)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography ml={2} variant="body1">
              Aucun produit n'est encore créé par un utilisateur
            </Typography>
          )}
        </TableContainer>
      </Box>

      {/* Confirm deleteProduct dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmDeleteProductDialog}
        setOpenConfirmDialog={setOpenConfirmDeleteProductDialog}
        handleCancelClick={() => {
          setOpenConfirmDeleteProductDialog(false);
        }}
        handleConfirmClick={handleConfirmDeleteProductClick}
        title={"Êtes-vous sur ?"}
        content={"Voulez-vous supprimer ce produit ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      {/* Confirm approveProduct dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmApprovedProductDialog}
        setOpenConfirmDialog={setOpenConfirmApprovedProductDialog}
        handleCancelClick={() => {
          setOpenConfirmApprovedProductDialog(false);
        }}
        handleConfirmClick={handleConfirmApprovedProductClick}
        title={"Êtes-vous sur ?"}
        content={"Voulez-vous approuver ce produit ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      {/* Confirm DisapproveProduct dialog */}
      <ConfirmDialog
        openConfirmDialog={openConfirmDisapprovedProductDialog}
        setOpenConfirmDialog={setOpenConfirmDisapprovedProductDialog}
        handleCancelClick={() => {
          setOpenConfirmDisapprovedProductDialog(false);
        }}
        handleConfirmClick={handleConfirmDisapprovedProductClick}
        title={"Êtes-vous sur ?"}
        content={"Voulez-vous désapprouver ce produit ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />
    </>
  );
}
