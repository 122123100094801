import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";
import RoutesName from "../data/RoutesName";
import axios from "../lib/axios";
import ApiRoutes from "../data/ApiRoutes";
import authHeader from "../utils/authHeader";
import { useLocalStorage } from "./useLocalStorage";

// @ts-ignore
export const useProductCategory = ({
  errorsHandler,
  globalErrorsHandler,
} = {}) => {
  const navigate = useNavigate();
  const { apiKey, setApiKey } = useAuth();
  const [user, setUser] = useLocalStorage("user", null);

  const processError = (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("An error happened on the server");
      console.error("The HTTP status code is ", error.response.status);
      console.error(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("The request was made but no response was received");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("An unknown error occurred");
      console.error(error);
    }
    if (error.response.status === 401 || error.response.status === 419) {
      console.log("You are not authenticated");
      console.log("You are not authenticated");
      setUser(null);
      setApiKey(null)
      navigate(RoutesName.login);
    }
  };

  // @ts-ignore
  const getById = async ({ productCategoryId, onErrors, onSuccess } = {}) => {
    console.log("@@@The product category is ", productCategoryId)
    try {
      let res = await axios.get(
        ApiRoutes.productCategory.getById(productCategoryId)
      );
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const allWithProductAttributes = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.get(
        ApiRoutes.productCategory.allWithProductAttributes()
      );
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const allShort = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.get(ApiRoutes.productCategory.allShort());
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const all = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.get(ApiRoutes.productCategory.all());
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const register = async ({ onErrors, onSuccess, data } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.productCategory.register(), data, {
        headers: authHeader(apiKey),
      });
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const addProductAttribute = async ({ onErrors, onSuccess, data } = {}) => {
    try {
      let res = await axios.post(
        ApiRoutes.productCategory.addProductAttribute(),
        data,
        { headers: authHeader(apiKey) }
      );
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const removeProductAttributes = async ({
    onErrors,
    onSuccess,
    data,
  } = {}) => {
    try {
      let res = await axios.post(
        ApiRoutes.productCategory.removeProductAttributes(),
        data,
        { headers: authHeader(apiKey) }
      );
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const deleteProductCategory = async ({
    onErrors,
    onSuccess,
    productCategoryId,
  } = {}) => {
    try {
      let res = await axios.delete(
        ApiRoutes.productCategory.deleteProductCategory(productCategoryId),
        { headers: authHeader(apiKey) }
      );
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const updateProductCategory = async ({
    onErrors,
    onSuccess,
    productCategoryId,
    data,
  } = {}) => {
    try {
      let res = await axios.post(
        ApiRoutes.productCategory.updateProductCategory(productCategoryId),
        data,
        { headers: authHeader(apiKey) }
      );
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  return {
    getById,
    updateProductCategory,
    deleteProductCategory,
    removeProductAttributes,
    addProductAttribute,
    register,
    all,
    allShort,
    allWithProductAttributes,
  };
};
