import styled from "@emotion/styled";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormControl,
  Stack,
} from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import { ImageInput, StyledLabel } from "./ImageInput";
import MaterialIconPickerDialog from "./MaterialIconPickerDialog";

const DialogContentWrapper = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PreviewImage = styled("img")({
  maxWidth: "100%",
  maxHeight: "200px",
});
const StyledFormControl = styled(FormControl)`
  && {
    margin-bottom: 1rem;
  }
`;

const AddCategoryDialog = ({
  openAddCategoryDialog,
  setOpenAddCategoryDialog,
  handleAddCategory,
}) => {
  const [newCategory, setNewCategory] = useState({
    icon: "",
    name: "",
    description: "",
    main_illustration: "",
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [formErrors, setFormErrors] = useState({
    name: "",
    description: "",
    main_illustration: "",
  });

  const [showIconPickerDialog, setShowIconPickerDialog] = useState(false);

  const handleImageChange = (event) => {
    setNewCategory({
      ...newCategory,
      main_illustration: event.target.files[0],
    });
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };

  const handleCategoryValidation = () => {
    // Validate form
    let errors = {
      name: "",
      description: "",
      main_illustration: "",
    };

    if (!newCategory.name) {
      errors.name = "Le nom est obligatoire";
    }

    if (!newCategory.description) {
      errors.description = "La description est obligatoire";
    }

    if (
      newCategory.main_illustration &&
      // @ts-ignore
      newCategory.main_illustration.size > 1024 * 1024 * 5
    ) {
      errors.main_illustration =
        "La taille de l'illustration ne doit pas dépasser 5 Mo";
    }

    setFormErrors(errors);
    return !Object.values(errors).some((error) => error !== "");
  };

  const handleAddCategoryClick = () => {
    if (handleCategoryValidation()) handleAddCategory(newCategory);
  };

  const handleSelectIconClicked = () => {
    setShowIconPickerDialog(true);
  };

  const onIconSelected = (icon) => {
    setNewCategory({ ...newCategory, icon: icon })
    setShowIconPickerDialog(false)
  };

  return (
    <>
      <Dialog
        open={openAddCategoryDialog}
        onClose={() => setOpenAddCategoryDialog(false)}
      >
        <DialogTitle>Ajouter une nouvelle catégorie</DialogTitle>
        <DialogContentWrapper sx={{ minWidth: 600 }}>
          <TextField
            label="Nom"
            fullWidth
            margin="normal"
            value={newCategory.name}
            onChange={(event) =>
              setNewCategory({ ...newCategory, name: event.target.value })
            }
            error={formErrors.name !== ""}
            helperText={formErrors.name}
          />
          <TextField
            label="Description"
            fullWidth
            margin="normal"
            value={newCategory.description}
            onChange={(event) =>
              setNewCategory({
                ...newCategory,
                description: event.target.value,
              })
            }
            error={formErrors.description !== ""}
            helperText={formErrors.description}
          />
          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <ImageInput
              content={"Choisir une image"}
              onChange={handleImageChange}
            />
            {formErrors.main_illustration !== "" && (
              <p style={{ color: "red" }}>{formErrors.main_illustration}</p>
            )}
            {previewImage && (
              <ImageWrapper>
                <PreviewImage src={previewImage} alt="Preview" />
              </ImageWrapper>
            )}
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <Stack direction="row" spacing={4} alignItems="center">
              <StyledLabel
                onClick={handleSelectIconClicked}
                style={{ width: "200px" }}
              >
                Choisir une icône
              </StyledLabel>
              {newCategory.icon && (
                <span className="material-icons" style={{ fontSize: "30px" }}>
                  {newCategory.icon}
                </span>
              )}
            </Stack>
          </FormControl>
        </DialogContentWrapper>
        <Button
          sx={{ margin: "20px" }}
          variant="contained"
          color="primary"
          onClick={() => handleAddCategoryClick()}
        >
          Ajouter
        </Button>
      </Dialog>

      <MaterialIconPickerDialog
        open={showIconPickerDialog}
        onClose={() => setShowIconPickerDialog(false)}
        onIconPicked={onIconSelected}
      />
    </>
  );
};

export default AddCategoryDialog;
