// @ts-ignore
import ApiRoutes from "domain/data/ApiRoutes";
import RoutesName from "domain/data/RoutesName";
import axios from "domain/lib/axios";
import { useNavigate } from "react-router-dom";
import authHeader from "../utils/authHeader";
import { useLocalStorage } from "./useLocalStorage";

// @ts-ignore
export const useProduct = ({ errorsHandler, globalErrorsHandler } = {}) => {
  const navigate = useNavigate();
  const [apiKey, setApiKey] = useLocalStorage("apiKey");
  // @ts-ignore
  const [user, setUser] = useLocalStorage("user", null);

  // @ts-ignore
  const processError = (error) => {
    console.log("************************************************************");
    console.log("** LocalErrorHandler                                      **");
    console.log("************************************************************");
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("An error happened on the server");
      console.error("The HTTP status code is ", error.response.status);
      console.error(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("The request was made but no response was received");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("An unknown error occurred");
      console.error(error);
    }
    if (error.response.status === 401 || error.response.status === 419) {
      console.log("You are not authenticated");

      setUser(null);
      setApiKey(null);
      navigate(RoutesName.login);
    }
  };

  // @ts-ignore
  const getAll = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.get(ApiRoutes.product.getAll());
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const getById = async ({ onErrors, onSuccess, productId } = {}) => {
    try {
      let res = await axios.get(ApiRoutes.product.getById(productId));
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const genericFind = async ({ onErrors, onSuccess, data } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.genericFind(), data);
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const tagFind = async ({ onErrors, onSuccess, data } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.tagFind(), data);
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const productFind = async ({ onErrors, onSuccess, data } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.productFind(), data);
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const priceFindEqual = async ({ onErrors, onSuccess, data } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.priceFindEqual(), data);
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const priceFindGreater = async ({ onErrors, onSuccess, data } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.priceFindGreater(), data);
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const priceFindLower = async ({ onErrors, onSuccess, data } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.priceFindLower(), data);
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const mostLikesPodium = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.mostLikesPodium());
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const mostDislikesPodium = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.mostDislikesPodium());
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const mostCommentsPodium = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.mostCommentsPodium());
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const mostLikesAll = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.mostLikesAll());
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const mostDislikesAll = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.mostDislikesAll());
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const mostCommentsAll = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.mostCommentsAll());
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const register = async ({ onErrors, onSuccess, data } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.register(), data, {
        headers: authHeader(apiKey),
      });
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const update = async ({ onErrors, onSuccess, productId, data } = {}) => {
    try {
      let res = await axios.post(ApiRoutes.product.update(productId), data, {
        headers: authHeader(apiKey),
      });
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const deleteProduct = async ({ onErrors, onSuccess, productId } = {}) => {
    try {
      let res = await axios.delete(ApiRoutes.product.deleteProduct(productId), {
        headers: authHeader(apiKey),
      });
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const getAllUsersProducts = async ({ onErrors, onSuccess } = {}) => {
    try {
      let res = await axios.get(ApiRoutes.product.getAllUsersProducts(), {
        headers: authHeader(apiKey),
      });
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const approveProduct = async ({ onErrors, onSuccess, productId } = {}) => {
    try {
      let res = await axios.post(
        ApiRoutes.product.approveProduct(productId),
        null,
        {
          headers: authHeader(apiKey),
        }
      );
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  // @ts-ignore
  const disapproveProduct = async ({ onErrors, onSuccess, productId } = {}) => {
    try {
      let res = await axios.post(
        ApiRoutes.product.disapproveProduct(productId),
        null,
        {
          headers: authHeader(apiKey),
        }
      );
      if (onSuccess) onSuccess(res.data);
      return res.data;
    } catch (error) {
      console.log("An eror occurred");
      console.log(error);
      if (onErrors) onErrors(error);
      if (errorsHandler) errorsHandler(error);
      if (globalErrorsHandler) globalErrorsHandler(error);
      if (!onErrors || !errorsHandler) throw error;
    }
  };

  return {
    getAll,
    getById,
    genericFind,
    tagFind,
    productFind,
    priceFindEqual,
    priceFindGreater,
    priceFindLower,
    mostLikesPodium,
    mostDislikesPodium,
    mostCommentsPodium,
    mostLikesAll,
    mostDislikesAll,
    mostCommentsAll,
    register,
    update,
    deleteProduct,
    getAllUsersProducts,
    approveProduct,
    disapproveProduct,
  };
};
