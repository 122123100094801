import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLocalStorage } from "domain/hooks/useLocalStorage";
import { useLocation } from "react-router-dom";
import { useApi } from "domain/hooks/useApi";
import Swal from "sweetalert2";
import { showLoadingAlert } from "ui/utils/utils";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "ui/components/editor/tools/tools";

const FaqAddEditPage = () => {
  const location = useLocation();
  const [faq, setFaq] = React.useState({
    question: "",
    answer: {},
  });


  const [inEditMode, setInEditMode] = React.useState(false);
  const { FaqRequests } = useApi();

  const editorCore = React.useRef(null);
  const ReactEditorJS = createReactEditorJS();

  const handleInitialize = React.useCallback((instance) => {
    // await instance._editorJS.isReady;
    instance._editorJS.isReady
      .then(() => {
        // set reference to editor
        editorCore.current = instance;
      })
      .catch((err) => console.log("An error occured", err));
  }, []);

  const handleSave = React.useCallback(async () => {
    await editorCore.current.save();
  }, []);

  React.useEffect(() => {
    if (location.state && location.state.faqId) {
      showLoadingAlert();
      // @ts-ignore
      FaqRequests.getById({
        faqId: location.state.faqId,
      })
        .then((it) => {
          let parsedAnswer = JSON.parse(it.answer)
          setFaq({
            question: it.question,
            answer: parsedAnswer,
          });
          setInEditMode(true);
          editorCore.current.render(parsedAnswer)
          Swal.close();
        })
        .catch((e) => {
          Swal.fire({
            text: "Une erreur est survenue en essayant de charger la question que vous voulez éditer.",
            icon: "error",
          });
          setInEditMode(false);
        });
    }
  }, []);

  console.log("Faq is ", faq)

  const handleConfirmSaveClicked = async () => {
    showLoadingAlert();
    const savedData = await editorCore.current.save();
    
    setFaq({
      question: faq.question,
      answer: savedData
    })
    let promise = inEditMode
      ? // @ts-ignore
        FaqRequests.update({
          faqId: location.state.faqId,
          data: {
            question: faq.question,
            answer: JSON.stringify(savedData),
          },
        })
      : // @ts-ignore
        FaqRequests.register({
          data: {
            question: faq.question,
            answer: JSON.stringify(savedData),
          },
        });

    promise
      .then(() => {
        Swal.fire({
          text: "Votre sauveagarde a été effectué avec succès",
          icon: "success",
        });
      })
      .catch(() => {
        Swal.fire({
          text: "Une erreur est survenue en essayant d'enregistrer.",
          icon: "error",
        });
      });
  };

  return (
    <>
      <Box>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography sx={{ flexGrow: 1 }} variant="h5" component="h2">
            {inEditMode
              ? "Edition d'une question"
              : "Ajout d'une nouvelle question"}
          </Typography>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleConfirmSaveClicked()}
          >
            Enregistrer
          </Button>
        </Stack>
        <Box>
          <div>
            <TextField
              label="Question"
              fullWidth
              margin="normal"
              value={faq.question}
              onChange={(event) =>
                setFaq({ ...faq, question: event.target.value })
              }
              error={faq.question.trim() === ""}
              helperText={
                faq.question.trim() === ""
                  ? "Vous devez saisir une question"
                  : null
              }
            />
          </div>

          <Box style={{ marginTop: "20px" }}>
            <Typography sx={{ fontWeight: "bold" }} variant="h5" component="h2">
              Réponse
            </Typography>
            <Divider />
            <Box style={{ marginTop: "20px" }}>
              <ReactEditorJS
                onInitialize={handleInitialize}
                // @ts-ignore
                tools={EDITOR_JS_TOOLS}
                onChange={handleSave}
                // @ts-ignore
                defaultValue={faq.answer}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FaqAddEditPage;
