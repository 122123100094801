import ApiRoutes from 'domain/data/ApiRoutes'
import RoutesName from 'domain/data/RoutesName'
import axios from 'domain/lib/axios'
import { useNavigate } from 'react-router-dom'
import authHeader from "../utils/authHeader";
import {useAuth} from "./useAuth";
import {useLocalStorage} from "./useLocalStorage";

// @ts-ignore
export const useAdminSpace = ({errorsHandler, globalErrorsHandler}={}) => {

    const navigate = useNavigate()
    const {apiKey, updateData} = useAuth()
    const [user, setUser] = useLocalStorage("user", null)

    const processError = (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error("An error happened on the server");
            console.error("The HTTP status code is ", error.response.status);
            console.error(error.response.data);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("The request was made but no response was received");
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error("An unknown error occurred");
            console.error(error);
        }
        if (error.response.status === 401 || error.response.status === 419) {
            console.log("You are not authenticated")
            if (!globalErrorsHandler) {
                console.log("You are not authenticated")
                setUser(null)
                navigate(RoutesName.login)
            }
        }

    }

    if (!errorsHandler) errorsHandler = processError

    // @ts-ignore
    const allUsers = async({onErrors, onSuccess }={})=>{
        axios.get(ApiRoutes.adminSpace.allUsers(), {headers: authHeader(apiKey)})
            .then((res) => {
                if (onSuccess) onSuccess(res.data)
                return res.data
            })
            .catch(error => {
                console.log("An eror occurred")
                console.log(error);
                if (onErrors) onErrors(error)
                if (errorsHandler) errorsHandler(error)
            if (globalErrorsHandler) globalErrorsHandler(error)
                if (!onErrors || !errorsHandler) throw error
            })
    }

    // @ts-ignore
    const authenticatedUser = async({onErrors, onSuccess }={})=>{
        axios.get(ApiRoutes.adminSpace.authenticated(), {headers: authHeader(apiKey)})
        .then((res) => {
            if (onSuccess) onSuccess(res.data)
            updateData(res.data)
            return res.data
        })
        .catch(error => {
            console.log("An eror occurred")
            console.log(error);
            if (onErrors) onErrors(error)
            if (errorsHandler) errorsHandler(error)
            if (globalErrorsHandler) globalErrorsHandler(error)
            if (!onErrors || !errorsHandler) throw error
        })
    }

    // @ts-ignore
    const deleteUser = async({onErrors, onSuccess, userId }={})=>{
        axios.delete(ApiRoutes.adminSpace.delete(userId), {headers: authHeader(apiKey)})
        .then((res) => {
            if (onSuccess) onSuccess(res.data)
            return res.data
        })
        .catch(error => {
            console.log("An eror occurred")
            console.log(error);
            if (onErrors) onErrors(error)
            if (errorsHandler) errorsHandler(error)
            if (globalErrorsHandler) globalErrorsHandler(error)
            if (!onErrors || !errorsHandler) throw error
        })
    }

    // @ts-ignore
    const updateUser = async({onErrors, onSuccess, setErrors, userId, data }={})=>{
        axios.post(ApiRoutes.adminSpace.update(userId),  data,{headers: authHeader(apiKey)})
        .then((res) => {
            if (onSuccess) onSuccess(res.data)
            return res.data
        })
        .catch(error => {
            console.log("An eror occurred")
            console.log(error);
            if (onErrors) onErrors(error)
            if (setErrors) setErrors(error.response.data.errors)
            if (errorsHandler) errorsHandler(error)
            if (globalErrorsHandler) globalErrorsHandler(error)
            if (!onErrors && !setErrors && !errorsHandler) throw error
        })
    }

    // @ts-ignore
    return {
        updateUser,
        deleteUser,
        authenticatedUser,
        allUsers
    }
}


