import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Fab,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { showLoadingAlert } from "ui/utils/utils";
import ConfirmDialog from "ui/components/ConfirmDialog";
import { useApi } from "domain/hooks/useApi";
import RoutesName from "domain/data/RoutesName";

const FaqPage = () => {
  const [faqs, setFaqs] = React.useState([]);
  const [selectedFaq, setSelectedFaq] = React.useState(null);
  const [selectedFaqs, setSelectedFaqs] = React.useState([]);
  const [openConfirmDeleteFaqDialog, setOpenConfirmDeleteFaqDialog] =
    React.useState(false);
  const [openConfirmDeleteFaqsDialog, setOpenConfirmDeleteFaqsDialog] =
    React.useState(false);

  const { FaqRequests } = useApi();
  const navigate = useNavigate();

  React.useEffect(() => {
    loadQuestions();
  }, []);

  const loadQuestions = async () => {
    showLoadingAlert();
    FaqRequests.getAllQuestions()
      .then((data) => {
        console.log("Faq got");
        console.log(data);
        setFaqs(data);
        Swal.close();
      })
      .catch((e) => {
        Swal.fire({
          text: "Une erreur est survenue en chargeant les données.",
          icon: "error",
        });
      });
  };

  const handleDeleteFaqClick = (faq) => {
    setSelectedFaq(faq);
    setOpenConfirmDeleteFaqDialog(true);
  };

  const handleEditClick = (faq)=>{
    setSelectedFaq(faq)
    navigate(RoutesName.faq.edit2, {state: {faqId: faq.id}})
  }

  const handleAddClick = ()=>{
    navigate(RoutesName.faq.add2)
  }

  const handleDeleteFaqsClick = () => {
    setOpenConfirmDeleteFaqsDialog(true);
  };

  const handleToggleFaqSelection = (checked, faq) => {
    if (checked && !selectedFaqs.includes(faq)) {
      setSelectedFaqs([...selectedFaqs, faq]);
    } else if (!checked) {
      setSelectedFaqs(selectedFaqs.filter((it) => it.id != faq.id));
    }
  };

  const handleConfirmDeleteFaqClicked = async () => {
    showLoadingAlert();
    // @ts-ignore
    FaqRequests.deleteById({ faqId: selectedFaq.id })
      .then(() => {
        let filtered = faqs.filter((it) => it.id != selectedFaq.id);
        let selectedFiltered = selectedFaqs.filter(
          (it) => it.id != selectedFaq.id
        );
        setSelectedFaq(null);
        setSelectedFaqs(selectedFiltered);
        setOpenConfirmDeleteFaqDialog(false);
        setFaqs(filtered);
        Swal.fire({
          text: "La question a été supprimée avec succès",
          icon: "success",
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Une erreur est survenue en essayant de supprimer la question",
        });
      });
  };

  const handleConfirmDeleteFaqsClicked = async () => {
    showLoadingAlert();

    const deletePromises = selectedFaqs.map((selectedFaq) =>
      // @ts-ignore
      FaqRequests.deleteById({ faqId: selectedFaq.id })
    );

    Promise.all(deletePromises)
      .then(() => {
        const filteredFaqs = faqs.filter((faq) => !selectedFaqs.includes(faq));
        setSelectedFaqs([]);
        setOpenConfirmDeleteFaqsDialog(false);
        setFaqs(filteredFaqs);
        Swal.fire({
          text: "Les questions ont été supprimées avec succès",
          icon: "success",
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Une erreur est survenue en essayant de supprimer les questions",
        });
      });
  };

  return (
    <>
      <Box style={{ userSelect: "none" }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography
            sx={{ flexGrow: 1, textAlign: "center" }}
            variant="h5"
            component="h2"
            align="center"
          >
            Liste des questions
          </Typography>

          {selectedFaqs.length > 0 && (
            <Button
              variant="outlined"
              color="error"
              onClick={()=>handleDeleteFaqsClick()}
            >
              Supprimer
            </Button>
          )}

          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleAddClick()}
          >
            Ajouter
          </Button>
        </Stack>
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          {faqs.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={selectedFaqs.length === faqs.length}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedFaqs(faqs)
                          : setSelectedFaqs([])
                      }
                    />
                  </TableCell>
                  <TableCell>Questions</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {faqs.map((faq) => (
                  <TableRow
                    key={faq.id}
                    selected={
                      selectedFaqs.includes(faq) ||
                      (selectedFaq && selectedFaq.id == faq.id)
                    }
                    onClick={() => {
                      if (selectedFaq && selectedFaq.id == faq.id)
                        setSelectedFaq(null);
                      else setSelectedFaq(faq);
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        checked={selectedFaqs.includes(faq)}
                        onChange={(_, checked) =>
                          handleToggleFaqSelection(checked, faq)
                        }
                      />
                    </TableCell>
                    <TableCell>{faq.question}</TableCell>
                    <TableCell>
                      <Tooltip title="Editer la question">
                        <IconButton
                          onClick={() => handleEditClick(faq)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Supprimer la sous-catégorie">
                        <IconButton onClick={() => handleDeleteFaqClick(faq)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography ml={2} variant="body1">
              Aucune question-réponse n'est encore créée
            </Typography>
          )}
        </TableContainer>
      </Box>
      <ConfirmDialog
        openConfirmDialog={openConfirmDeleteFaqsDialog}
        setOpenConfirmDialog={setOpenConfirmDeleteFaqsDialog}
        handleCancelClick={() => setOpenConfirmDeleteFaqsDialog(false)}
        handleConfirmClick={handleConfirmDeleteFaqsClicked}
        title={"Enregistrer les changements"}
        content={"Voulez-vous supprimer ces questions ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />

      <ConfirmDialog
        openConfirmDialog={openConfirmDeleteFaqDialog}
        setOpenConfirmDialog={setOpenConfirmDeleteFaqDialog}
        handleCancelClick={() => setOpenConfirmDeleteFaqDialog(false)}
        handleConfirmClick={handleConfirmDeleteFaqClicked}
        title={"Enregistrer les changements"}
        content={"Voulez-vous supprimer cette question ?"}
        cancelText={"Non"}
        confirmText={"Oui"}
      />
    </>
  );
};

export default FaqPage;
