
import {useProduct} from "./useProduct";
import {useAdminSpace} from "./useAdminSpace";
import {useAuth} from "./useAuth";
import {useProductCategory} from "./useProductCategory";
import {useProductAttribute} from "./useProductAttribute";
import {useProductSubCategory} from "./useProductSubCategory";
import {useFaq} from "./useFaq";
import RoutesName from "../data/RoutesName";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "./useLocalStorage";
import { useUser } from "./useUser";

// @ts-ignore
export const useApi = ({errorsHandler, globalErrorsHandler}={}) => {

    const navigate = useNavigate()
    const [user, setUser] = useLocalStorage("user", null)
    const processError = (error) => {
        console.log("GlobalErrorsHandler")
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error("An error happened on the server");
            console.error("The HTTP status code is ", error.response.status);
            console.error(error.response.data);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("The request was made but no response was received");
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error("An unknown error occurred");
            console.error(error);
        }
        if (error.response.status === 401 || error.response.status === 419 || error.response.status === 403) {
            console.log("You are not authenticated")
            setUser(null)
            navigate(RoutesName.login)
        }

    }

    if (!globalErrorsHandler) globalErrorsHandler = processError

    return {
        // @ts-ignore
        AuthRequests: useAuth({errorsHandler, globalErrorsHandler}),
        ProductRequests: useProduct({errorsHandler, globalErrorsHandler}),
        AdminSpaceRequests: useAdminSpace({errorsHandler, globalErrorsHandler}),
        ProductCategoryRequests: useProductCategory({errorsHandler, globalErrorsHandler}),
        ProductAttributeRequests: useProductAttribute({errorsHandler, globalErrorsHandler}),
        ProductSubCategoryRequests: useProductSubCategory({errorsHandler, globalErrorsHandler}),
        FaqRequests: useFaq({errorsHandler, globalErrorsHandler}),
        UserRequests: useUser({errorsHandler, globalErrorsHandler}),
    }
}




