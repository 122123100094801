import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  Typography,
  Stack,
  Checkbox,
  Button,
  FormControlLabel,
  Tooltip,
  OutlinedInput,
  Card,
} from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import * as React from "react";
import { useAuth } from "domain/hooks/useAuth";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputError from "ui/components/InputError";
import Swal from "sweetalert2";
import swal from "sweetalert"
import RoutesName from "domain/data/RoutesName";

export default function LoginPage() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [shouldRemember, setShouldRemember] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [status, setStatus] = React.useState(null)

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();


  const { login } = useAuth({
    middleware: "guest",
    redirectIfAuthenticated: RoutesName.dashboard
  })

  React.useEffect(() => {
    let reset = searchParams.get["reset"];
    if (reset?.length > 0 && errors.length === 0) {
      setStatus(atob(reset));
    } else {
      setStatus(null);
    }
  }, [searchParams.get, errors.length]);

  const submitForm = async (event) => {
    event.preventDefault();

    
      await login({
        email,
        password,
        remember: shouldRemember,
        setErrors,
        setStatus,
        onErrors: (error)=> {
          Swal.fire({
            title: "Oops...",
            text: "Une erreur est survenue",
            icon: "error"
          })
          console.log(error)
        }
      });
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <Box sx={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}} alignItems="center" justifyContent="center">
      <Card sx={{padding: 2, mt: 6}}>

      <Stack spacing={2} width={500}>
        <Typography variant="h4" fontSize={28} textAlign="center">
          Connexion à mon compte
        </Typography>
        <Typography
          variant="subtitle1"
          fontSize={14}
          textAlign="center"
          lineHeight={1.4}
        >
          Ces identfiants vous donneront accès à l'espace admin du site
          lecobaye.fr et partenaires**.
        </Typography>
        {/* Session Status */}
        
        
          <Stack direction="column" spacing={2} pt={4}>
            <FormControl>
              <InputLabel htmlFor="input-email">Adresse e-mail</InputLabel>
              <OutlinedInput
                id="input-email"
                label="Adresse e-mail *"
                placeholder="Adresse e-mail *"
                type="email"
                onChange={(event) => setEmail(event.target.value)}
                required
                autoFocus
                sx={{ width: "100%" }}
              />
              <InputError
                messages={
                  // @ts-ignore
                  errors.email
                }
              />
            </FormControl>
          </Stack>
          <FormControl>
            <InputLabel htmlFor="input-password">Mot de passe</InputLabel>
            <OutlinedInput
              id="input-password"
              label="Mot de passe *"
              type={showPassword ? "text" : "password"}
              onChange={(event) => setPassword(event.target.value)}
              required
              autoComplete="current-password"
              sx={{ width: "100%" }}
              endAdornment={
                <InputAdornment position="end">
                  <Typography
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                  >
                    Voir mot de passe
                  </Typography>
                </InputAdornment>
              }
            />
            <InputError
              messages={
                // @ts-ignore
                errors.password
              }
            />
          </FormControl>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => setShouldRemember(e.target.checked)}
                />
              }
              label={
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography>Se souvenir de moi</Typography>
                  <Tooltip title="Si cette option est activée, votre session sera sauvegardée">
                    <HelpOutline color="error" fontSize="small" />
                  </Tooltip>
                </Stack>
              }
            />
            <Typography
              onClick={handleForgotPassword}
              sx={{
                "&:hover": { textDecoration: "underline" },
                cursor: "pointer",
              }}
            >
              Mot de passe outblié {">"}
            </Typography>
          </Stack>
          <Stack mt={2}>
            <Button onClick={submitForm} variant="contained">
              Me connecter
            </Button>
          </Stack>
        
      </Stack>
      </Card>
    </Box>
  );
}
